<template>
    <div align="center" v-if="is_mounted">
        <br>
        <div align="center">
            <span class="text-h4">Questionario assuntivo {{ tipo_questionario }}</span>
            <br>
            <strong>Completa il questionario sotto con le informazioni mancanti</strong>
            <hr>
        </div>
        <br><br>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="is_loading"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div v-if="isRcTecnico">
            <QQQuestionarioRCTecnico
                v-if="isRcTecnico"
                @update:isValid="onIsValid"
                ref="questionario"
                :indice_questionario_rischio="indice_prodotto"
            />
        </div>

        <div v-if="isRcMedico">
            <QQQuestionarioRCMedico
                v-if="isRcMedico"
                @update:isValid="onIsValid"
                ref="questionario"
                :indice_questionario_rischio="indice_prodotto"
            />

        </div>

        <div v-if="isRcSanitario">
                <QQQuestionarioRCSanitario
                v-if="isRcSanitario"
                @update:isValid="onIsValid"
                ref="questionario"
                :indice_questionario_rischio="indice_prodotto"
            />

        </div>

        <div v-if="isAltroRamo">
            <QQQuestionarioPersoneAziende
                @update:isValid="onIsValid"
                ref="questionario"
                :indice_questionario_rischio="indice_prodotto"
            />
        </div>

        <hr>

        <div class="row" align="center">
            <div class="col-12 col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>
            <div class="col-12 col-md-4" align="center">
                <QQButton label="ANNULLA EMISSIONE" color="blue-grey" icon="mdi-close-octagon" size="md"
                    @click.native="gotoStart()"
                />
            </div>
            <div class="col-12 col-md-4" align="center">
                <QQButton :label="label_quotazione"
                    color="blue-grey"
                    icon="mdi-arrow-right-bold"
                    size="md"
                    icon_align="right"
                    :disabled="is_loading"
                    @click.native="onAvantiClicked"
                />
            </div>
        </div>

        <br><br><br><br><br><br>
    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import { mapState, mapActions } from "vuex";
    import { mapGetters } from "vuex";
    import { mapFields } from 'vuex-map-fields';
    import commonLib from "@/libs/commonLib";
    import QQQuestionarioRCTecnico from "@/components/QuestionariAssuntivi/Professionisti/QQQuestionarioRCTecnico";
    import QQQuestionarioRCMedico from "@/components/QuestionariAssuntivi/Professionisti/QQQuestionarioRCMedico";
    import QQQuestionarioRCSanitario from "@/components/QuestionariAssuntivi/Professionisti/QQQuestionarioRCSanitario";
    import QQQuestionarioPersoneAziende from "@/components/QuestionariAssuntivi/Persone_Aziende/QQQuestionarioPersoneAziende";

    export default {
        name: "QuestionarioGenerico",
        components: {
            QQQuestionarioRCTecnico,
            QQQuestionarioRCMedico,
            QQQuestionarioRCSanitario,
            QQQuestionarioPersoneAziende,
            QQButton
        },
        data() {
            return {
                questionario_valido: false,
                indice_prodotto: 0,
                is_mounted: false,
                is_loading: false
            }
        },
        computed: {
            ...mapGetters([
                "jwtPayload"
            ]),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                analisi_necessita: state => state.formPreventivo.preventivo.analisi_necessita,
                analisi_rischio: state => state.formPreventivo.preventivo.analisi_rischio,
                //is_loading: state => state.formPreventivo.is_loading
            }),
            ...mapFields('gestioneQuotazioni', [
                "questionari_assuntivi"
            ]),
            tipo_questionario() {
                if (this.isRcTecnico) return "R.C. Tecnico";
                if (this.isRcMedico) return "R.C. Medico";
                if (this.isRcSanitario) return "R.C. Sanitario non Medico";

                return "";
            },
            label_quotazione() {
                if (this.indice_prodotto < (this.totaleQuestionariAssuntivi-1)) {
                    return "AVANTI"
                }

                return "RICHIEDI QUOTAZIONE";
            },
            isRcTecnico() {
                return !this.isRcMedico && !this.isRcSanitario && !this.isAltroRamo;
            },
            isRcMedico() {
                let lavoro = this.getRispostaAnalisiRischiofromName("lavoro");
                if (lavoro === "") {
                    lavoro = this.getRispostaAnalisiNecessitafromName("lavoro");
                }

                //let lavoro = this.getRispostaAnalisiNecessitafromName("lavoro");
                return lavoro.toLowerCase() === "medico";
            },
            isRcSanitario() {
                //let lavoro = this.getRispostaAnalisiNecessitafromName("lavoro");
                let lavoro = this.getRispostaAnalisiRischiofromName("lavoro");
                if (lavoro === "") {
                    lavoro = this.getRispostaAnalisiNecessitafromName("lavoro");
                }
                return lavoro.toLowerCase() === "sanitario_non_medico";
            },
            listaTaylormade() {
                return this.formPreventivo.preventivo.prodotti.filter(prodotto => {
                    return prodotto.is_checked && (prodotto.is_taylormade || prodotto.is_taylor_made);
                });
            },
            isAltroRamo() {
                var taylormade = this.listaTaylormade;

                console.log("taylormade:",taylormade);

                // Cerca quelli che non sono tecnici, medici e sanitari non medici
                if (commonLib.isEmpty(taylormade)) return false;
                taylormade = taylormade[this.indice_prodotto];

                return (taylormade.id !== "rcp_tecnico") &&
                       (taylormade.id !== "rcp_medico") &&
                       (taylormade.id !== "rcp_sanitario");
            },
            isProdottoSenzaQuestionario() {
                var taylormade = this.listaTaylormade;
                // Cerca quelli che non sono tecnici, medici e sanitari non medici
                if (commonLib.isEmpty(taylormade)) return false;
                taylormade = taylormade[this.indice_prodotto];

                switch(taylormade.id) {
                    case "spese_veterinarie":
                    case "deo":
                    case "rc_impresa_edile":
                    case "tutela_legale_medici":
                    case "tutela_legale_professionisti":
                    case "cauzioni":
                    case "fideiussione":
                    case "asseverazioni":
                    case "donazioni":
                    case "droni":
                        return true;
                }

                return false;
            },
            totaleQuestionariAssuntivi() {
                var taylormade = this.listaTaylormade;

                return taylormade.length;
            },
            tutti_i_prodotti() {
                return this.formPreventivo.preventivo.prodotti.filter(prodotto => {
                    return prodotto.is_checked;
                });
            },
       /*     get_indice_prodotto() {
                var taylormade = this.listaTaylormade;
                // Cerca quelli che non sono tecnici, medici e sanitari non medici
                if (commonLib.isEmpty(taylormade)) return false;
                taylormade = taylormade[this.indice_prodotto];

                var prodotti_attivi = this.tutti_i_prodotti;
                var indice = 0;

                // var prodotto_identificato = prodotti_attivi[this.indice_prodotto];

                for (var i=0;i<prodotti_attivi.length;i++) {
                    var prodotto = prodotti_attivi[i];
                    if (prodotto.id === taylormade.id) indice = i;
                }

                console.log("indice_prodotto:",indice);

                return indice;
            },*/
        //    totaleTaylormade() {
        //        var taylormade = this.formPreventivo.preventivo.prodotti.filter(prodotto => {
        //            return prodotto.is_checked &&  prodotto.is_taylormade;
        //        });
        //    }
        },
        methods: {
            ...mapActions({
                saveQuestionariAssuntivi: "gestioneQuotazioni/saveQuestionariAssuntivi",
                saveQuestionarioAssuntivoSpecifico: "gestioneQuotazioni/saveQuestionarioAssuntivoSpecifico"
            }),
            getRispostaAnalisiNecessitafromName(name) {
                if (!commonLib.isEmpty(this.analisi_necessita)) {
                    var domanda = this.analisi_necessita.find(d => {
                        return d.name === name;
                    });

                    // Se la domanda non e` presente nel questionario allora domanda=undefined
                    if (commonLib.isEmpty(domanda)) return "";

                    return domanda.value.toUpperCase();
                }

                return "";
            },
            getRispostaAnalisiRischiofromName(name) {
                if (!commonLib.isEmpty(this.analisi_rischio)) {
                    var analisi_rischio = this.analisi_rischio[this.indice_prodotto];
                    var domande = analisi_rischio.domande;
                    var domanda = domande.find(d => {
                        return d.name === name;
                    });
                    if (commonLib.isEmpty(domanda)) return "";
                    return domanda.value.toUpperCase();
                }

                return "";
            },
            elenco_prodotti_attivi() {
                if (commonLib.isEmpty(this.formPreventivo)) return [];

                return this.formPreventivo.preventivo.prodotti.filter(p => {
                    return p.is_checked && (p.is_taylormade || p.is_taylor_made);
                });
            },
            totale_prodotti_attivi() {
                var elenco = this.elenco_prodotti_attivi();
                return elenco.length;
            },
            onIndietroClicked() {
                this.formPreventivo.step = 8;
                this.$router.push({name : "Preventivi.FormPreventivo"});
            },
            onIsValid(isValid) {
                this.questionario_valido = isValid;
                console.log("isValid:",isValid);
            },
            async onAvantiClicked() {
                this.is_loading = true;

                this.$refs.questionario.forzaValidazione();

                // Aggiorna un campo di testo per attivare tutti i controlli
                if (!this.isProdottoSenzaQuestionario) {
                    if (!this.questionario_valido) {
                        this.$q.dialog({
                                title: 'Attenzione',
                                message: 'Devi compilare tutti i campi obbligatori prima di continuare.'
                            });
                        this.is_loading = false;
                        return;
                    }
                }


                this.$store.dispatch("formPreventivo/reset_is_loading");
                await this.saveQuestionariAssuntivi(this.formPreventivo.preventivo.id);

                var prodotto = this.elenco_prodotti_attivi()[this.indice_prodotto];
                console.log("sono qui",prodotto,this.indice_prodotto);
                console.log("id_prodotto:",prodotto.id);

                // Registra la singola quotazione associata al questionario che stiamo registrando
                await this.saveQuestionarioAssuntivoSpecifico(
                    {
                        id_preventivo:this.formPreventivo.preventivo.id,
                        id_prodotto: prodotto.id
                    }
                );

                // Incrementa l'Indice Prodotto
                this.indice_prodotto++;

                //console.log("Incremento indice prodotto:",this.indice_prodotto);
                //if (this.indice_prodotto >= this.totale_prodotti_attivi()) {

                console.log("lista taylormade:",this.listaTaylormade);
                console.log("totale questionari:",this.totaleQuestionariAssuntivi);
                console.log("indice prodotto:",this.indice_prodotto);

                if (this.indice_prodotto >= (this.totaleQuestionariAssuntivi)) {
                    this.$router.push({name : "Preventivi"});
                }

                this.is_loading = false;
            }
        },
        mounted() {
            window.scrollTo(0,0);
            this.indice_prodotto = 0;

            this.is_mounted = true;
        },
        activated() {
            window.scrollTo(0,0);
            this.indice_prodotto = 0;
        }
    }
</script>
