<template>
    <div
        class="row justify-center"
        @input="resultDomande()"
        v-if="domande_caricate"
    >

        <div class="col-12 col-md-10" align="left">

            <legend> <strong>Proponente - Informazioni generali</strong> </legend>

            <q-list separator>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Cognome, Nome / Ragione Sociale</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("cognome")}} {{getValueFromNameCliente("nome")}} {{getValueFromNameCliente("ragione_sociale")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Indirizzo</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("indirizzo_residenza")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Comune</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("comune_residenza")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Provincia</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("provincia_residenza")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>CAP</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("cap_residenza")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Codice Fiscale</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("codice_fiscale")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Partita IVA</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("partita_iva")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Comune di nascita</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("comune_nascita")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Provincia di nascita</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("provincia_nascita")}}</q-item-label>
                    </q-item-section>
                </q-item>

            </q-list>
            <br>

            <div v-if="isMultirischioCasa">

                <fieldset>
                    <legend>Multirischio della casa</legend>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Tipologia di utilizzo dell'abitazione</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("tipo_dimora") }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Tipo di Abitazione</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipo_abitazione")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>A quale piano si trova l'Immobile?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("piano_abitazione")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>

                            <q-item-section>
                                <q-item-label>

                                    <Domanda
                                        :domanda=" domande_multirischio_casa.indirizzo_casa"
                                        ref="domande_multirischio_casa.indirizzo_casa"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_multirischio_casa.comune_casa)"
                                        :domanda="domande_multirischio_casa.comune_casa"
                                        ref="domande_multirischio_casa.comune_casa"
                                        @update:isValid="onDomandaValidated"
                                        @update:domanda="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_multirischio_casa.cap_casa)"
                                        :domanda="domande_multirischio_casa.cap_casa"
                                        ref="domande_multirischio_casa.cap_casa"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("provincia_fabbricato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("provincia_fabbricato")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Quanti sono i metri quadri dell`Immobile?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("metri_quadri")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Anno di costruzione del fabbricato?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_fabbricato")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Quale e` l`anno di ultima ristrutturazione dell'Immobile?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_ultima_ristrutturazione")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>

                    <br>
                    <legend> <strong>Caratteristiche generali</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-show="!isTaylorMade">Compagnia</q-item-label>
                                <q-item-label v-show="!isTaylorMade">
                                    {{getCompagniaSelezionata}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption v-show="!isTaylorMade">{{garanzia.label}}</q-item-label>
                                <q-item-label v-show="!isTaylorMade">
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>

                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                       <q-item>
                            <q-item-section>
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("polizza_in_corso") }} </q-item-label>
                            </q-item-section>
                            <div v-show="getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ">
                                <q-item >
                                    <q-item-section>
                                        <q-item-label caption>Impresa di assicurazione</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('compagnia')}}</q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label caption>Data di scadenza della copertura</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('scadenza_copertura')}}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </q-item>

                    <!--    <q-item v-if="havePolizzaStessoRischio() === 'SI'">
                            <q-item-section>
                                <q-item-label>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_multirischio_casa.nome_assicuratore_casa)"
                                        :domanda="domande_multirischio_casa.nome_assicuratore_casa"
                                        ref="domande_multirischio_casa.nome_assicuratore_casa"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_multirischio_casa.copertura_assicurativa_precedente.premio_lordo)"
                                        :domanda="domande_multirischio_casa.copertura_assicurativa_precedente.premio_lordo"
                                        ref="domande.copertura_assicurativa_precedente.premio_lordo"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-label>
                            </q-item-section>
                        </q-item> //-->

                       <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("polizza_schifata") }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_casa')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_casa")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_casa')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_casa')) === 'Si'"
                                        :domanda="domande_multirischio_casa.data_sinistro_casa"
                                        ref="domande_multirischio_casa.data_sinistro_casa"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_casa')) === 'Si'"
                                        :domanda="domande_multirischio_casa.descrizione_evento_casa"
                                        ref="domande_multirischio_casa.descrizione_evento_casa"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_casa')) === 'Si'"
                                        :domanda="domande_multirischio_casa.importo_liquidato_casa"
                                        ref="domande_multirischio_casa.importo_liquidato_casa"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>
                        </q-item>

                    </q-list>

                </fieldset>

            </div>

            <div v-if="isInfortuni">

                <fieldset>
                    <legend>Infortuni</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    v-if="domandaIsVisible(domande_infortuni.contraente_uguale_assicurato)"
                                    :domanda="domande_infortuni.contraente_uguale_assicurato"
                                    ref="domande_infortuni.contraente_uguale_assicurato"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <div v-if="domande_infortuni.contraente_uguale_assicurato.value === 'no'">
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.tipo_persona"
                                        ref="domande_infortuni.tipo_persona"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item v-if="domande_infortuni.tipo_persona.value === 'persona_fisica'">
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.cognome"
                                        ref="domande_infortuni.cognome"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.nome"
                                        ref="domande_infortuni.nome"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.codice_fiscale"
                                        ref="domande_infortuni.codice_fiscale"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.sesso"
                                        ref="domande_infortuni.sesso"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item v-if="domande_infortuni.tipo_persona.value === 'professionista'">
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.cognome"
                                        ref="domande_infortuni.cognome"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.nome"
                                        ref="domande_infortuni.nome"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.partita_iva"
                                        ref="domande_infortuni.partita_iva"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item v-if="domande_infortuni.tipo_persona.value === 'persona_fisica'">
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.stato_nascita_infortuni"
                                        ref="domande_infortuni.stato_nascita_infortuni"
                                        @update:isValid="onDomandaValidated"

                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.data_nascita"
                                        ref="domande_infortuni.data_nascita"
                                        @update:isValid="onDomandaValidated"
                                    />

                                </q-item-section>
                            </q-item>
                            <q-item v-if="((domande_infortuni.tipo_persona.value === 'persona_fisica') && (domande_infortuni.stato_nascita_infortuni.value === 'ITALIA'))">
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.comune_nascita_infortuni"
                                        ref="domande_infortuni.comune_nascita_infortuni"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.cap_nascita_infortuni"
                                        ref="domande_infortuni.cap_nascita_infortuni"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.provincia_nascita_infortuni"
                                        ref="domande_infortuni.provincia_nascita_infortuni"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item v-if="domande_infortuni.tipo_persona.value === 'persona_fisica' || domande_infortuni.tipo_persona.value === 'professionista'">
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.indirizzo_infortuni"
                                        ref="domande_infortuni.indirizzo_infortuni"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.comune_infortuni"
                                        ref="domande_infortuni.comune_infortuni"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.cap_infortuni"
                                        ref="domande_infortuni.cap_infortuni"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.provincia_infortuni"
                                        ref="domande_infortuni.provincia_infortuni"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item v-if="domande_infortuni.tipo_persona.value === 'societa'">
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.ragione_sociale"
                                        ref="domande_infortuni.ragione_sociale"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.partita_iva"
                                        ref="domande_infortuni.partita_iva"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item v-if="domande_infortuni.tipo_persona.value === 'societa'">
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.indirizzo_sede_infortuni"
                                        ref="domande_infortuni.indirizzo_infortuni"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.comune_sede_infortuni"
                                        ref="domande_infortuni.comune_sede_infortuni"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.cap_sede_infortuni"
                                        ref="domande_infortuni.cap_sede_infortuni"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_infortuni.provincia_sede_infortuni"
                                        ref="domande_infortuni.provincia_sede_infortuni"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>

                        </div>

                    </q-list>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qual'è l'ttivit&agrave; svolta dall'Assicurato?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("attivita_infortuni")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>L`Assicurato ha più di 75 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("eta_assicurato_infortuni")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('accertamenti_diagnostici')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("accertamenti_diagnostici")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('subito_ricoveri')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("subito_ricoveri")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('dipendenze')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("dipendenze")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('trauni_fratture')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("trauni_fratture")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('pensione_rendita_invalidita_infortunio')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("pensione_rendita_invalidita_infortunio")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('pensione_rendita_invalidita_malattia')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("pensione_rendita_invalidita_malattia")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('tipologia_copertura')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_infortuni.motivo_rifiuto_compagnia"
                                    ref="domande_infortuni.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_infortuni')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_infortuni")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_infortuni')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_infortuni')) === 'Si'"
                                        :domanda="domande_infortuni.data_sinistro_infortuni"
                                        ref="domande_infortuni.data_sinistro_infortuni"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_infortuni')) === 'Si'"
                                        :domanda="domande_infortuni.descrizione_evento_infortuni"
                                        ref="domande_infortuni.descrizione_evento_infortuni"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_infortuni')) === 'Si'"
                                        :domanda="domande_infortuni.importo_liquidato_infortuni"
                                        ref="domande_infortuni.importo_liquidato_infortuni"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isMultirischioCommercio">
                <fieldset>
                    <legend>Multirischio del Commercio</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("settore_merceologico_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("settore_merceologico_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("merce_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("merce_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("fatturato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("attivita_aggiuntiva_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("attivita_aggiuntiva_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('attivita_aggiuntiva_commercio')) === 'Si'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("settore_merceologico_aggiuntivo_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("settore_merceologico_aggiuntivo_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('attivita_aggiuntiva_commercio')) === 'Si'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("merce_commercio_aggiuntiva") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("merce_commercio_aggiuntiva")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_soci_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_soci_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("dipendenti_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("dipendenti_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('dipendenti_commercio')) === 'Si'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_dipendenti_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_dipendenti_commercio")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("materiale_costruzione_commercio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("materiale_costruzione_commercio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_commercio.numero_piani_edificio"
                                    ref="domande_multirischio_commercio.numero_piani_edificio"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_commercio.metri_quadri"
                                    ref="domande_multirischio_commercio.metri_quadri"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_commercio.anno_costruzione"
                                    ref="domande_multirischio_commercio.anno_costruzione"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_commercio.ubicazione_diverso_sede"
                                    ref="domande_multirischio_commercio.ubicazione_diverso_sede"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_multirischio_commercio.ubicazione_diverso_sede.value === 'si'">
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_commercio.indirizzo_commercio"
                                    ref="domande_multirischio_commercio.indirizzo_commercio"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_commercio.comune_commercio"
                                    ref="domande_multirischio_commercio.comune_commercio"
                                    @update:isValid="onDomandaValidated"
                                    @qq-domanda-change="onDomandaChange($event)"
                                    @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_commercio.cap_commercio"
                                    ref="domande_multirischio_commercio.cap_commercio"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_commercio.provincia_commercio"
                                    ref="domande_multirischio_commercio.provincia_commercio"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_multirischio_commercio.motivo_rifiuto_compagnia"
                                    ref="domande_multirischio_commercio.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_commercio')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_commercio")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_commercio')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_commercio')) === 'Si'"
                                        :domanda="domande_multirischio_commercio.data_sinistro_artigiano"
                                        ref="domande_multirischio_commercio.data_sinistro_artigiano"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_commercio')) === 'Si'"
                                        :domanda="domande_multirischio_commercio.descrizione_evento_artigiano"
                                        ref="domande_multirischio_commercio.descrizione_evento_artigiano"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_commercio')) === 'Si'"
                                        :domanda="domande_multirischio_commercio.importo_liquidato_artigiano"
                                        ref="domande_multirischio_commercio.importo_liquidato_artigiano"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isMultirischioArtigiano">
                <fieldset>
                    <legend>Multirischio dell`Artigianato</legend>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("settore_merceologico") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("settore_merceologico")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("merce_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("merce_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("fatturato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("attivita_aggiuntiva_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("attivita_aggiuntiva_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('attivita_aggiuntiva_artigiano')) === 'Si'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("settore_merceologico_aggiuntivo_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("settore_merceologico_aggiuntivo_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('attivita_aggiuntiva_artigiano')) === 'Si'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("merce_aggiuntiva_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("merce_aggiuntiva_artigiano")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_soci_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_soci_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("dipendenti_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("dipendenti_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('dipendenti_artigiano')) === 'Si'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_dipendenti_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_dipendenti_artigiano")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("materiale_costruzione_artigiano") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("materiale_costruzione_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_artigiano.numero_piani_edificio"
                                    ref="domande_multirischio_artigiano.numero_piani_edificio"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_artigiano.metri_quadri"
                                    ref="domande_multirischio_artigiano.metri_quadri"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_artigiano.anno_costruzione"
                                    ref="domande_multirischio_artigiano.anno_costruzione"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_artigiano.ubicazione_diverso_sede"
                                    ref="domande_multirischio_artigiano.ubicazione_diverso_sede"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_multirischio_artigiano.ubicazione_diverso_sede.value === 'si'">
                            <q-item-section>
                                <Domanda
                                    v-if="domande_multirischio_artigiano.ubicazione_diverso_sede.value === 'si'"
                                    :domanda="domande_multirischio_artigiano.indirizzo_artigiano"
                                    ref="domande_multirischio_artigiano.indirizzo_artigiano"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    v-if="domande_multirischio_artigiano.ubicazione_diverso_sede.value === 'si'"
                                    :domanda="domande_multirischio_artigiano.comune_artigiano"
                                    ref="domande_multirischio_artigiano.comune_artigiano"
                                    @update:isValid="onDomandaValidated"
                                    @qq-domanda-change="onDomandaChange($event)"
                                    @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    v-if="domande_multirischio_artigiano.ubicazione_diverso_sede.value === 'si'"
                                    :domanda="domande_multirischio_artigiano.cap_artigiano"
                                    ref="domande_multirischio_artigiano.cap_artigiano"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    v-if="domande_multirischio_artigiano.ubicazione_diverso_sede.value === 'si'"
                                    :domanda="domande_multirischio_artigiano.provincia_artigiano"
                                    ref="domande_multirischio_artigiano.provincia_artigiano"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_multirischio_artigiano.motivo_rifiuto_compagnia"
                                    ref="domande_multirischio_artigiano.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_artigiano')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_artigiano")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_artigiano')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_artigiano')) === 'Si'"
                                        :domanda="domande_multirischio_artigiano.data_sinistro_artigiano"
                                        ref="domande_multirischio_artigiano.data_sinistro_artigiano"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_multirischio_artigiano.descrizione_evento_artigiano"
                                        ref="domande_multirischio_artigiano.descrizione_evento_artigiano"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_multirischio_artigiano.importo_liquidato_artigiano"
                                        ref="domande_multirischio_artigiano.importo_liquidato_artigiano"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>

                        </q-item>

                    </q-list>
                </fieldset>
            </div>

            <div v-if="isGlobaleFabbricato">
                <fieldset>
                    <legend>Multirischio del Condominio</legend>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_fabbricato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_fabbricato")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_globale_fabbricato.anno_ristrutturazione"
                                    ref="domande_globale_fabbricato.anno_ristrutturazione"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_globale_fabbricato.totale_fabbricati"
                                    ref="domande_globale_fabbricato.totale_fabbricati"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>

                            <q-item-section>
                                <Domanda
                                    :domanda="domande_globale_fabbricato.piani_interrati"
                                    ref="domande_globale_fabbricato.piani_interrati"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_globale_fabbricato.piani_fuori_terra"
                                    ref="domande_globale_fabbricato.piani_fuori_terra"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("negozi_cinema_fabbricato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("negozi_cinema_fabbricato")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section >  <!-- v-if="getRispostaAnalisiRischioFromName('negozi_cinema_fabbricato').includes('cinema')" -->
                                <Domanda
                                    :domanda="domande_globale_fabbricato.metri_quadri_cinema"
                                    ref="domande_globale_fabbricato.metri_quadri_cinema"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section> <!-- v-if="getDomandaQuestionariFromName('negozi_cinema_fabbricato').includes('negozi')" -->
                                <Domanda
                                    :domanda="domande_globale_fabbricato.metri_quadri_negozi"
                                    ref="domande_globale_fabbricato.metri_quadri_negozi"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section > <!-- v-if="getDomandaQuestionariFromName('negozi_cinema_fabbricato').includes('negozi')" -->
                                <Domanda
                                    :domanda="domande_globale_fabbricato.attivita_negozi"
                                    ref="domande_globale_fabbricato.attivita_negozi"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_globale_fabbricato.indirizzo_condominio"
                                    ref="domande_globale_fabbricato.indirizzo_condominio"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_globale_fabbricato.comune_condominio"
                                    ref="domande_globale_fabbricato.comune_condominio"
                                    @update:isValid="onDomandaValidated"
                                    @qq-domanda-change="onDomandaChange($event)"
                                    @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_globale_fabbricato.cap_condominio"
                                    ref="domande_globale_fabbricato.cap_condominio"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_globale_fabbricato.provincia_condominio"
                                    ref="domande_globale_fabbricato.provincia_condominio"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_globale_fabbricato.motivo_rifiuto_compagnia"
                                    ref="domande_globale_fabbricato.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_fabbricato')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_fabbricato")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_fabbricato')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_fabbricato')) === 'Si'"
                                        :domanda="domande_globale_fabbricato.data_sinistro_fabbricato"
                                        ref="domande_globale_fabbricato.data_sinistro_fabbricato"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_fabbricato')) === 'Si'"
                                        :domanda="domande_globale_fabbricato.descrizione_evento_fabbricato"
                                        ref="domande_globale_fabbricato.descrizione_evento_fabbricato"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_fabbricato')) === 'Si'"
                                        :domanda="domande_globale_fabbricato.importo_liquidato_fabbricato"
                                        ref="domande_globale_fabbricato.importo_liquidato_fabbricato"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>

                </fieldset>
            </div>

            <div v-if="isPolizzaVirus">
                <fieldset>
                    <legend>Polizza 3-Virus</legend>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_globale_fabbricato.motivo_rifiuto_compagnia"
                                    ref="domande_globale_fabbricato.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_virus')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_virus")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isTutelaReddito">
                <fieldset>
                    <legend>Protezione del reddito</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("eta_assicurato_reddito") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("eta_assicurato_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipo_lavoro_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipo_lavoro_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaQuestionariFromName('tipologia_attivita_persona') === 'DIPENDENTE_PRIVATO'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("tempo_indeterminato_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tempo_indeterminato_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaQuestionariFromName('tipologia_attivita_persona') === 'DIPENDENTE_PUBBLICO'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("tempo_indeterminato_pubblico_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tempo_indeterminato_pubblico_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaQuestionariFromName('tipologia_attivita_persona') === 'PROFESSIONISTA'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("tempo_partitaiva_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tempo_partitaiva_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaQuestionariFromName('tipologia_attivita_persona') === 'IMPRENDITORE'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("tempo_partitaiva_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tempo_partitaiva_reddito")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaQuestionariFromName('tipologia_attivita_persona') === 'AZIENDA'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("tempo_partitaiva_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tempo_partitaiva_reddito")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_tutela_reddito.descrizione_lavoro_svolto"
                                    ref="domande_tutela_reddito.descrizione_lavoro_svolto"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("perdita_impiego_reddito") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("perdita_impiego_reddito")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_globale_fabbricato.motivo_rifiuto_compagnia"
                                    ref="domande_globale_fabbricato.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>

                    </q-list>
                    <br>

                </fieldset>
            </div>

            <div v-if="isVeterinaria">
                <fieldset>
                    <legend>Spese Veterinarie</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("totale_animali_veterinaria") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("totale_animali_veterinaria")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("animali_rabbiosio_veterinaria") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("animali_rabbiosio_veterinaria")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>Tipo di Animale</q-item-section>
                            <q-item-section>Nome</q-item-section>
                            <q-item-section>Razza</q-item-section>
                            <q-item-section>Eta` anni</q-item-section>
                            <q-item-section>Num.Microchip</q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.tipo_animale1"
                                    ref="domande_veterinaria.tipo_animale1"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.nome_animale1"
                                    ref="domande_veterinaria.nome_animale1"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.razza_animale1"
                                    ref="domande_veterinaria.razza_animale1"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.eta_animale1"
                                    ref="domande_veterinaria.eta_animale1"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.microchip_animale1"
                                    ref="domande_veterinaria.microchip_animale1"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_animali > 1">
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.tipo_animale2"
                                    ref="domande_veterinaria.tipo_animale2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.nome_animale2"
                                    ref="domande_veterinaria.nome_animale2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.razza_animale2"
                                    ref="domande_veterinaria.razza_animale2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.eta_animale2"
                                    ref="domande_veterinaria.eta_animale2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.microchip_animale2"
                                    ref="domande_veterinaria.microchip_animale2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_animali > 2">
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.tipo_animale3"
                                    ref="domande_veterinaria.tipo_animale2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.nome_animale3"
                                    ref="domande_veterinaria.nome_animale2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.razza_animale3"
                                    ref="domande_veterinaria.razza_animale2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.eta_animale3"
                                    ref="domande_veterinaria.eta_animale2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.microchip_animale3"
                                    ref="domande_veterinaria.microchip_animale2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_animali > 3">
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.tipo_animale4"
                                    ref="domande_veterinaria.tipo_animale4"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.nome_animale4"
                                    ref="domande_veterinaria.nome_animale4"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.razza_animale4"
                                    ref="domande_veterinaria.razza_animale4"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.eta_animale4"
                                    ref="domande_veterinaria.eta_animale4"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.microchip_animale4"
                                    ref="domande_veterinaria.microchip_animale4"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_animali > 4">
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.tipo_animale5"
                                    ref="domande_veterinaria.tipo_animale5"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.nome_animale5"
                                    ref="domande_veterinaria.nome_animale5"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.razza_animale5"
                                    ref="domande_veterinaria.razza_animale5"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.eta_animale5"
                                    ref="domande_veterinaria.eta_animale5"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_veterinaria.microchip_animale5"
                                    ref="domande_veterinaria.microchip_animale5"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_veterinaria.motivo_rifiuto_compagnia"
                                    ref="domande_veterinaria.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_veterinaria')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_veterinaria")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_veterinaria')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_veterinaria')) === 'Si'"
                                        :domanda="domande_veterinaria.data_sinistro_veterinario"
                                        ref="domande_veterinaria.data_sinistro_veterinario"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_veterinaria')) === 'Si'"
                                        :domanda="domande_veterinaria.descrizione_evento_veterinario"
                                        ref="domande_veterinaria.descrizione_evento_veterinario"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_veterinaria')) === 'Si'"
                                        :domanda="domande_veterinaria.importo_liquidato_veterinario"
                                        ref="domande_veterinaria.importo_liquidato_veterinario"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isMultirischioAlbergo">
                <fieldset>
                    <legend>Multirischio Albergo</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_albergo.ubicazione_uguale_sede"
                                    ref="domande_multirischio_albergo.ubicazione_uguale_sede"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <template v-if="domande_multirischio_albergo.ubicazione_uguale_sede.value === 'no'">
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_multirischio_albergo.indirizzo)"
                                        :domanda="domande_multirischio_albergo.indirizzo"
                                        ref="domande_multirischio_albergo.indirizzo"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_multirischio_albergo.comune_albergo)"
                                        :domanda="domande_multirischio_albergo.comune_albergo"
                                        ref="domande_multirischio_albergo.comune_albergo"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_multirischio_albergo.cap_albergo)"
                                        :domanda="domande_multirischio_albergo.cap_albergo"
                                        ref="domande_multirischio_albergo.cap_albergo"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_multirischio_albergo.provincia_albergo)"
                                        :domanda="domande_multirischio_albergo.provincia_albergo"
                                        ref="domande_multirischio_albergo.provincia_albergo"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>


                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_albergo.denominazione_struttura"
                                    ref="domande_multirischio_albergo.denominazione_struttura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_albergo.posti_letto"
                                    ref="domande_multirischio_albergo.posti_letto"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_albergo.valutazione"
                                    ref="domande_multirischio_albergo.valutazione"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("struttura_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("struttura_albergo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("materiale_costruzione_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("materiale_costruzione_albergo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_albergo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("piani_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("piani_albergo")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_albergo.superficie_metri_quadri"
                                    ref="domande_multirischio_albergo.superficie_metri_quadri"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("fatturato") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("fatturato")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_soci_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_soci_albergo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("dipendenti_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("dipendenti_albergo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_dipendenti_albergo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_dipendenti_albergo")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_veterinaria.motivo_rifiuto_compagnia"
                                    ref="domande_multirischio_albergo.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_albergo')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_albergo")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_albergo')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_albergo')) === 'Si'"
                                        :domanda="domande_multirischio_albergo.data_sinistro_albergo"
                                        ref="domande_multirischio_albergo.data_sinistro_albergo"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_albergo')) === 'Si'"
                                        :domanda="domande_multirischio_albergo.descrizione_evento_albergo"
                                        ref="domande_multirischio_albergo.descrizione_evento_albergo"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_albergo')) === 'Si'"
                                        :domanda="domande_multirischio_albergo.importo_liquidato_albergo"
                                        ref="domande_multirischio_albergo.importo_liquidato_albergo"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isDeO">
                <fieldset>
                    <legend>Polizza D & O</legend>
                    <br>

                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("forma_giuridica_deo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("forma_giuridica_deo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('forma_giuridica_deo') === 'ASSOCIAZIONE'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_associazione_deo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_associazione_deo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('forma_giuridica_deo') === 'SOCIETA'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_societa_deo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_societa_deo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("ateco_deo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("ateco_deo")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("attivo_bilancio_deo") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("attivo_bilancio_deo")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_veterinaria.motivo_rifiuto_compagnia"
                                    ref="domande_multirischio_albergo.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_deo')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_deo")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isPolizzaViaggi">
                <fieldset>
                    <legend>Polizza Viaggi</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("attivita_viaggi") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("attivita_viaggi")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('tipologia_viaggio') !== 'SHENGEN'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("area_partenza_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("area_partenza_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('tipologia_viaggio') === 'RILASCIO_RESIDENZA'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("area_partenza_residenza_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("area_partenza_residenza_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('tipologia_viaggio') !== 'SHENGEN'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("paese_destinazione_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("paese_destinazione_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('tipologia_viaggio') === 'SHENGEN'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("paese_destinazione_shengen_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("paese_destinazione_shengen_viaggio")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("data_partenza_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("data_partenza_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("data_rientro_viaggio") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("data_rientro_viaggio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_viaggiatori") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_viaggiatori")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>

                    <fieldset>
                        <legend>Viaggiatore #1</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cognome1"
                                        ref="domande_viaggi.cognome1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.nome1"
                                        ref="domande_viaggi.nome1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cf1"
                                        ref="domande_viaggi.cf1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.data_nascita1"
                                        ref="domande_viaggi.data_nascita1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune_nascita1"
                                        ref="domande_viaggi.comune_nascita1"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia_nascita1"
                                        ref="domande_viaggi.provincia_nascita1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.indirizzo1"
                                        ref="domande_viaggi.indirizzo1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune1"
                                        ref="domande_viaggi.comune1"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cap1"
                                        ref="domande_viaggi.cap1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia1"
                                        ref="domande_viaggi.provincia1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 1">
                        <legend>Viaggiatore #2</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cognome2"
                                        ref="domande_viaggi.cognome2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.nome2"
                                        ref="domande_viaggi.nome2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cf2"
                                        ref="domande_viaggi.cf2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.data_nascita2"
                                        ref="domande_viaggi.data_nascita2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune_nascita2"
                                        ref="domande_viaggi.comune_nascita2"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia_nascita2"
                                        ref="domande_viaggi.provincia_nascita2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.indirizzo2"
                                        ref="domande_viaggi.indirizzo2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune2"
                                        ref="domande_viaggi.comune2"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cap2"
                                        ref="domande_viaggi.cap2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia2"
                                        ref="domande_viaggi.provincia2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 2">
                        <legend>Viaggiatore #3</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cognome3"
                                        ref="domande_viaggi.cognome3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.nome3"
                                        ref="domande_viaggi.nome3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cf3"
                                        ref="domande_viaggi.cf3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.data_nascita3"
                                        ref="domande_viaggi.data_nascita3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune_nascita3"
                                        ref="domande_viaggi.comune_nascita3"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia_nascita3"
                                        ref="domande_viaggi.provincia_nascita3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.indirizzo3"
                                        ref="domande_viaggi.indirizzo3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune3"
                                        ref="domande_viaggi.comune3"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cap3"
                                        ref="domande_viaggi.cap3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia3"
                                        ref="domande_viaggi.provincia3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 3">
                        <legend>Viaggiatore #4</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cognome4"
                                        ref="domande_viaggi.cognome4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.nome4"
                                        ref="domande_viaggi.nome4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cf4"
                                        ref="domande_viaggi.cf4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.data_nascita4"
                                        ref="domande_viaggi.data_nascita4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune_nascita4"
                                        ref="domande_viaggi.comune_nascita4"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia_nascita4"
                                        ref="domande_viaggi.provincia_nascita4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.indirizzo4"
                                        ref="domande_viaggi.indirizzo4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune4"
                                        ref="domande_viaggi.comune4"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cap4"
                                        ref="domande_viaggi.cap4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia4"
                                        ref="domande_viaggi.provincia4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 4">
                        <legend>Viaggiatore #5</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cognome5"
                                        ref="domande_viaggi.cognome5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.nome5"
                                        ref="domande_viaggi.nome5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cf5"
                                        ref="domande_viaggi.cf5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.data_nascita5"
                                        ref="domande_viaggi.data_nascita5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune_nascita5"
                                        ref="domande_viaggi.comune_nascita5"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia_nascita5"
                                        ref="domande_viaggi.provincia_nascita5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.indirizzo5"
                                        ref="domande_viaggi.indirizzo5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune5"
                                        ref="domande_viaggi.comune5"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cap5"
                                        ref="domande_viaggi.cap5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia5"
                                        ref="domande_viaggi.provincia5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 5">
                        <legend>Viaggiatore #6</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cognome6"
                                        ref="domande_viaggi.cognome6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.nome6"
                                        ref="domande_viaggi.nome6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cf6"
                                        ref="domande_viaggi.cf6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.data_nascita6"
                                        ref="domande_viaggi.data_nascita6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune_nascita6"
                                        ref="domande_viaggi.comune_nascita6"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia_nascita6"
                                        ref="domande_viaggi.provincia_nascita6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.indirizzo6"
                                        ref="domande_viaggi.indirizzo6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune6"
                                        ref="domande_viaggi.comune6"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cap6"
                                        ref="domande_viaggi.cap6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia6"
                                        ref="domande_viaggi.provincia6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 6">
                        <legend>Viaggiatore #7</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cognome7"
                                        ref="domande_viaggi.cognome7"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.nome7"
                                        ref="domande_viaggi.nome7"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cf7"
                                        ref="domande_viaggi.cf7"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.data_nascita7"
                                        ref="domande_viaggi.data_nascita7"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune_nascita7"
                                        ref="domande_viaggi.comune_nascita7"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia_nascita7"
                                        ref="domande_viaggi.provincia_nascita6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.indirizzo7"
                                        ref="domande_viaggi.indirizzo7"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune7"
                                        ref="domande_viaggi.comune7"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cap7"
                                        ref="domande_viaggi.cap7"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia7"
                                        ref="domande_viaggi.provincia7"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 7">
                        <legend>Viaggiatore #8</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cognome8"
                                        ref="domande_viaggi.cognome8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.nome8"
                                        ref="domande_viaggi.nome8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cf8"
                                        ref="domande_viaggi.cf8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.data_nascita8"
                                        ref="domande_viaggi.data_nascita8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune_nascita8"
                                        ref="domande_viaggi.comune_nascita8"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia_nascita8"
                                        ref="domande_viaggi.provincia_nascita8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.indirizzo8"
                                        ref="domande_viaggi.indirizzo8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune8"
                                        ref="domande_viaggi.comune8"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cap8"
                                        ref="domande_viaggi.cap8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia8"
                                        ref="domande_viaggi.provincia8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 8">
                        <legend>Viaggiatore #9</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cognome9"
                                        ref="domande_viaggi.cognome9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.nome9"
                                        ref="domande_viaggi.nome9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cf9"
                                        ref="domande_viaggi.cf9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.data_nascita9"
                                        ref="domande_viaggi.data_nascita9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune_nascita9"
                                        ref="domande_viaggi.comune_nascita9"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia_nascita9"
                                        ref="domande_viaggi.provincia_nascita9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.indirizzo9"
                                        ref="domande_viaggi.indirizzo9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune9"
                                        ref="domande_viaggi.comune9"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cap9"
                                        ref="domande_viaggi.cap9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia9"
                                        ref="domande_viaggi.provincia9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_viaggiatori') > 9">
                        <legend>Viaggiatore #10</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cognome10"
                                        ref="domande_viaggi.cognome10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.nome10"
                                        ref="domande_viaggi.nome10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cf10"
                                        ref="domande_viaggi.cf10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.data_nascita10"
                                        ref="domande_viaggi.data_nascita10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune_nascita10"
                                        ref="domande_viaggi.comune_nascita10"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia_nascita10"
                                        ref="domande_viaggi.provincia_nascita10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.indirizzo10"
                                        ref="domande_viaggi.indirizzo10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.comune10"
                                        ref="domande_viaggi.comune10"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.cap10"
                                        ref="domande_viaggi.cap10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_viaggi.provincia10"
                                        ref="domande_viaggi.provincia10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>



                </fieldset>
            </div>

            <div v-if="isPolizzaLeasing">
                <fieldset>
                    <legend>Polizza Leasing</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("durata_leasing") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("durata_leasing")) }} Anni</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("valore_beni_leasing") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("valore_beni_leasing")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_leasing.descrizione_beni_leasing"
                                    ref="domande_leasing.descrizione_beni_leasing"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_leasing.descrizione_attivita_leasing"
                                    ref="domande_leasing.descrizione_attivita_leasing"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_leasing.ubicazione_uguale_sede"
                                    ref="domande_leasing.ubicazione_uguale_sede"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section v-if="domande_leasing.ubicazione_uguale_sede.value == 'no'">
                                <Domanda
                                    :domanda="domande_leasing.indirizzo"
                                    ref="domande_leasing.indirizzo"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_leasing.ubicazione_uguale_sede.value == 'no'">
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_leasing.comune_leasing"
                                    ref="domande_leasing.comune_leasing"
                                    @update:isValid="onDomandaValidated"
                                    @qq-domanda-change="onDomandaChange($event)"
                                    @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_leasing.cap_leasing"
                                    ref="domande_leasing.cap_leasing"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_leasing.provincia_leasing"
                                    ref="domande_leasing.provincia_leasing"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_leasing.motivo_rifiuto_compagnia"
                                    ref="domande_leasing.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_leasing')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_leasing")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_leasing')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_leasing')) === 'Si'"
                                        :domanda="domande_leasing.data_sinistro_leasing"
                                        ref="domande_leasing.data_sinistro_leasing"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_leasing')) === 'Si'"
                                        :domanda="domande_leasing.descrizione_evento_leasing"
                                        ref="domande_leasing.descrizione_evento_leasing"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_leasing')) === 'Si'"
                                        :domanda="domande_leasing.importo_liquidato_leasing"
                                        ref="domande_leasing.importo_liquidato_leasing"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isPatrimoniale">
                <fieldset>
                    <legend>R.C. Patrimoniale</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_cariche_patrimoniale") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_cariche_patrimoniale")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    v-if="domandaIsVisible(domande_patrimoniale.contraente_uguale_assicurato)"
                                    :domanda="domande_patrimoniale.contraente_uguale_assicurato"
                                    ref="domande_patrimoniale.contraente_uguale_assicurato"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>

                        <div v-if="domande_patrimoniale.contraente_uguale_assicurato.value === 'no'">
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.cognome"
                                        ref="domande_patrimoniale.cognome"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.nome"
                                        ref="domande_patrimoniale.nome"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.codice_fiscale"
                                        ref="domande_patrimoniale.codice_fiscale"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.sesso"
                                        ref="domande_patrimoniale.sesso"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.stato_nascita_patrimoniale"
                                        ref="domande_patrimoniale.stato_nascita_patrimoniale"
                                        @update:isValid="onDomandaValidated"

                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.data_nascita"
                                        ref="domande_patrimoniale.data_nascita"
                                        @update:isValid="onDomandaValidated"
                                    />

                                </q-item-section>
                            </q-item>
                            <q-item v-if="domande_patrimoniale.stato_nascita_patrimoniale.value === 'ITALIA'">
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.comune_nascita_patrimoniale"
                                        ref="domande_patrimoniale.comune_nascita_patrimoniale"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.cap_nascita_patrimoniale"
                                        ref="domande_patrimoniale.cap_nascita_patrimoniale"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.provincia_nascita_patrimoniale"
                                        ref="domande_patrimoniale.provincia_nascita_patrimoniale"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.indirizzo_patrimoniale"
                                        ref="domande_patrimoniale.indirizzo_patrimoniale"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.comune_patrimoniale"
                                        ref="domande_patrimoniale.comune_patrimoniale"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.cap_patrimoniale"
                                        ref="domande_patrimoniale.cap_patrimoniale"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        :domanda="domande_patrimoniale.provincia_patrimoniale"
                                        ref="domande_patrimoniale.provincia_patrimoniale"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </div>
                    </q-list>
                    <br>
                    <fieldset>
                        <legend>Carica #1</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') == 1">
                                    <q-item-label caption>{{ getLabelQuestionariFromName("carica_svolta_patrimoniale") }} </q-item-label>
                                    <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("carica_svolta_patrimoniale")) }}</q-item-label>
                                </q-item-section>
                                <q-item-section v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 1">
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.carica_svolta1)"
                                        :domanda="domande_patrimoniale.carica_svolta1"
                                        ref="domande_patrimoniale.carica_svolta1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.ente_appartenenza1)"
                                        :domanda="domande_patrimoniale.ente_appartenenza1"
                                        ref="domande_patrimoniale.ente_appartenenza1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.citta_ente_appartenenza1)"
                                        :domanda="domande_patrimoniale.citta_ente_appartenenza1"
                                        ref="domande_patrimoniale.citta_ente_appartenenza1"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 1">
                        <legend>Carica #2</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.carica_svolta2)"
                                        :domanda="domande_patrimoniale.carica_svolta2"
                                        ref="domande_patrimoniale.carica_svolta2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.ente_appartenenza2)"
                                        :domanda="domande_patrimoniale.ente_appartenenza2"
                                        ref="domande_patrimoniale.ente_appartenenza2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.citta_ente_appartenenza2)"
                                        :domanda="domande_patrimoniale.citta_ente_appartenenza2"
                                        ref="domande_patrimoniale.citta_ente_appartenenza2"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 2">
                        <legend>Carica #3</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.carica_svolta3)"
                                        :domanda="domande_patrimoniale.carica_svolta3"
                                        ref="domande_patrimoniale.carica_svolta3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.ente_appartenenza3)"
                                        :domanda="domande_patrimoniale.ente_appartenenza3"
                                        ref="domande_patrimoniale.ente_appartenenza3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.citta_ente_appartenenza3)"
                                        :domanda="domande_patrimoniale.citta_ente_appartenenza3"
                                        ref="domande_patrimoniale.citta_ente_appartenenza3"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 3">
                        <legend>Carica #4</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.carica_svolta4)"
                                        :domanda="domande_patrimoniale.carica_svolta4"
                                        ref="domande_patrimoniale.carica_svolta4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.ente_appartenenza4)"
                                        :domanda="domande_patrimoniale.ente_appartenenza4"
                                        ref="domande_patrimoniale.ente_appartenenza4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.citta_ente_appartenenza4)"
                                        :domanda="domande_patrimoniale.citta_ente_appartenenza4"
                                        ref="domande_patrimoniale.citta_ente_appartenenza4"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 4">
                        <legend>Carica #5</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.carica_svolta5)"
                                        :domanda="domande_patrimoniale.carica_svolta5"
                                        ref="domande_patrimoniale.carica_svolta5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.ente_appartenenza5)"
                                        :domanda="domande_patrimoniale.ente_appartenenza5"
                                        ref="domande_patrimoniale.ente_appartenenza5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.citta_ente_appartenenza5)"
                                        :domanda="domande_patrimoniale.citta_ente_appartenenza5"
                                        ref="domande_patrimoniale.citta_ente_appartenenza5"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 5">
                        <legend>Carica #6</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.carica_svolta6)"
                                        :domanda="domande_patrimoniale.carica_svolta6"
                                        ref="domande_patrimoniale.carica_svolta6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.ente_appartenenza6)"
                                        :domanda="domande_patrimoniale.ente_appartenenza6"
                                        ref="domande_patrimoniale.ente_appartenenza6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.citta_ente_appartenenza6)"
                                        :domanda="domande_patrimoniale.citta_ente_appartenenza6"
                                        ref="domande_patrimoniale.citta_ente_appartenenza6"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 6">
                        <legend>Carica #7</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.carica_svolta7)"
                                        :domanda="domande_patrimoniale.carica_svolta7"
                                        ref="domande_patrimoniale.carica_svolta7"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.ente_appartenenza7)"
                                        :domanda="domande_patrimoniale.ente_appartenenza7"
                                        ref="domande_patrimoniale.ente_appartenenza7"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.citta_ente_appartenenza7)"
                                        :domanda="domande_patrimoniale.citta_ente_appartenenza7"
                                        ref="domande_patrimoniale.citta_ente_appartenenza7"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 7">
                        <legend>Carica #8</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.carica_svolta8)"
                                        :domanda="domande_patrimoniale.carica_svolta8"
                                        ref="domande_patrimoniale.carica_svolta8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.ente_appartenenza8)"
                                        :domanda="domande_patrimoniale.ente_appartenenza8"
                                        ref="domande_patrimoniale.ente_appartenenza8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.citta_ente_appartenenza8)"
                                        :domanda="domande_patrimoniale.citta_ente_appartenenza8"
                                        ref="domande_patrimoniale.citta_ente_appartenenza8"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 8">
                        <legend>Carica #9</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.carica_svolta9)"
                                        :domanda="domande_patrimoniale.carica_svolta9"
                                        ref="domande_patrimoniale.carica_svolta9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.ente_appartenenza9)"
                                        :domanda="domande_patrimoniale.ente_appartenenza9"
                                        ref="domande_patrimoniale.ente_appartenenza9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.citta_ente_appartenenza9)"
                                        :domanda="domande_patrimoniale.citta_ente_appartenenza9"
                                        ref="domande_patrimoniale.citta_ente_appartenenza9"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>

                    <fieldset v-if="getRispostaAnalisiRischioFromName('numero_cariche_patrimoniale') > 9">
                        <legend>Carica #10</legend>
                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.carica_svolta10)"
                                        :domanda="domande_patrimoniale.carica_svolta10"
                                        ref="domande_patrimoniale.carica_svolta10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.ente_appartenenza10)"
                                        :domanda="domande_patrimoniale.ente_appartenenza10"
                                        ref="domande_patrimoniale.ente_appartenenza10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_patrimoniale.citta_ente_appartenenza10)"
                                        :domanda="domande_patrimoniale.citta_ente_appartenenza10"
                                        ref="domande_patrimoniale.citta_ente_appartenenza10"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </fieldset>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomagetDomandaQuestionariFromNamendaAnalisiRischioFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_patrimoniale.motivo_rifiuto_compagnia"
                                    ref="domande_patrimoniale.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_patrimoniale')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_patrimoniale")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_patrimoniale')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_patrimoniale')) === 'Si'"
                                        :domanda="domande_veterinaria.data_sinistro_patrimoniale"
                                        ref="domande_veterinaria.data_sinistro_patrimoniale"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_patrimoniale')) === 'Si'"
                                        :domanda="domande_veterinaria.descrizione_evento_patrimoniale"
                                        ref="domande_veterinaria.descrizione_evento_patrimoniale"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_patrimoniale')) === 'Si'"
                                        :domanda="domande_veterinaria.importo_liquidato_patrimoniale"
                                        ref="domande_veterinaria.importo_liquidato_patrimoniale"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isMultirischioUfficio">
                <fieldset>
                    <legend>Multirischio Ufficio</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("attivita_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("attivita_ufficio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("fatturato") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("superfice_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("superfice_ufficio")) }} m.q.</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_ufficio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("piani_fabbricato_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("piani_fabbricato_ufficio")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("materiale_costruzione_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("materiale_costruzione_ufficio")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_soci_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_soci_ufficio")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("dipendenti_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("dipendenti_ufficio")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('dipendenti_ufficio') == 'SI'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_dipendenti_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_dipendenti_ufficio")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_multirischio_ufficio.ubicazione_diverso_sede)"
                                        :domanda="domande_multirischio_ufficio.ubicazione_diverso_sede"
                                        ref="domande_multirischio_ufficio.ubicazione_diverso_sede"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section v-if="domande_multirischio_ufficio.ubicazione_diverso_sede.value == 'si'">
                                <Domanda
                                    v-if="domande_multirischio_ufficio.ubicazione_diverso_sede.value == 'si'"
                                    :domanda="domande_multirischio_ufficio.indirizzo_ufficio"
                                    ref="domande_multirischio_ufficio.indirizzo_ufficio"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section v-if="domande_multirischio_ufficio.ubicazione_diverso_sede.value == 'si'">
                                <Domanda
                                    v-if="domande_multirischio_ufficio.ubicazione_diverso_sede.value == 'si'"
                                    :domanda="domande_multirischio_ufficio.comune_ufficio"
                                    ref="domande_multirischio_ufficio.comune_ufficio"
                                    @update:isValid="onDomandaValidated"
                                    @update:domanda="onDomandaChange($event)"
                                    @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                />
                            </q-item-section>
                            <q-item-section v-if="domande_multirischio_ufficio.ubicazione_diverso_sede.value == 'si'">
                                <Domanda
                                    v-if="domande_multirischio_ufficio.ubicazione_diverso_sede.value == 'si'"
                                    :domanda="domande_multirischio_ufficio.cap_ufficio"
                                    ref="domande_multirischio_ufficio.cap_ufficio"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("provincia_ufficio") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("provincia_ufficio")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    :domanda="domande_multirischio_ufficio.motivo_rifiuto_compagnia"
                                    ref="domande_multirischio_ufficio.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_ufficio')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_ufficio")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_ufficio')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_ufficio')) === 'Si'"
                                        :domanda="domande_multirischio_ufficio.data_sinistro_ufficio"
                                        ref="domande_multirischio_ufficio.data_sinistro_ufficio"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_ufficio')) === 'Si'"
                                        :domanda="domande_multirischio_ufficio.descrizione_evento_ufficio"
                                        ref="domande_multirischio_ufficio.descrizione_evento_ufficio"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_ufficio')) === 'Si'"
                                        :domanda="domande_multirischio_ufficio.importo_liquidato_ufficio"
                                        ref="domande_multirischio_ufficio.importo_liquidato_ufficio"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isRCEdilizia">
                <fieldset>
                    <legend>RC Impresa Edile</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("attivita_rc_edile") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("attivita_rc_edile")) }} Anni</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("fatturato") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_soci_rc_edile") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_soci_rc_edile")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("dipendenti_rc_edile") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("dipendenti_rc_edile")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('dipendenti_rc_edile') == 'SI'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("numero_dipendenti_rc_edile") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("numero_dipendenti_rc_edile")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("perc_lavori_subappalto_rc_edile") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("perc_lavori_subappalto_rc_edile")) }} %</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'"
                                    :domanda="domande_rc_edilizia.motivo_rifiuto_compagnia"
                                    ref="domande_rc_edilizia.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_rc_edile')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_rc_edile")) }} </q-item-label>
                            </q-item-section>
                            <template v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_rc_edile')) === 'Si'">
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_rc_edile')) === 'Si'"
                                        :domanda="domande_rc_edilizia.data_sinistro_edilizia"
                                        ref="domande_rc_edilizia.data_sinistro_edilizia"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_rc_edile')) === 'Si'"
                                        :domanda="domande_rc_edilizia.descrizione_evento_edilizia"
                                        ref="domande_rc_edilizia.descrizione_evento_edilizia"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="analizzaRisposta(getDomandaQuestionariFromName('sinistri_rc_edile')) === 'Si'"
                                        :domanda="domande_rc_edilizia.importo_liquidato_edilizia"
                                        ref="domande_rc_edilizia.importo_liquidato_edilizia"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </template>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isTCM">
                <fieldset>
                    <legend>Temporanea caso morte (TCM)</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("eta_tcm") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("eta_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("fumatore_tcm") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("fumatore_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label v-html="getLabelQuestionariFromName('durata_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("durata_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label v-html="getLabelQuestionariFromName('capitale_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("capitale_tcm")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.contraente_uguale_assicurato)"
                                        :domanda="domande_tcm.contraente_uguale_assicurato"
                                        ref="domande_tcm.contraente_uguale_assicurato"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.altezza_tcm)"
                                        :domanda="domande_tcm.altezza_tcm"
                                        ref="domande_tcm.altezza_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.peso_tcm)"
                                        :domanda="domande_tcm.peso_tcm"
                                        ref="domande_tcm.peso_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_tcm.contraente_uguale_assicurato.value == 'no'">
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.cognome)"
                                        :domanda="domande_tcm.cognome"
                                        ref="domande_tcm.cognome"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.nome)"
                                        :domanda="domande_tcm.nome"
                                        ref="domande_tcm.nome"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                        </q-item>

                        <q-item v-if="domande_tcm.contraente_uguale_assicurato.value == 'no'">
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.data_nascita)"
                                        :domanda="domande_tcm.data_nascita"
                                        ref="domande_tcm.data_nascita"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.codice_fiscale)"
                                        :domanda="domande_tcm.codice_fiscale"
                                        ref="domande_tcm.codice_fiscale"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.sesso)"
                                        :domanda="domande_tcm.sesso"
                                        ref="domande_tcm.partita_iva"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_tcm.contraente_uguale_assicurato.value == 'no'">
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.stato_nascita_tcm)"
                                        :domanda="domande_tcm.stato_nascita_tcm"
                                        ref="domande_tcm.stato_nascita_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                            <q-item-section v-if="domande_tcm.stato_nascita_tcm.value == 'ITALIA'">
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.comune_nascita_tcm)"
                                        :domanda="domande_tcm.comune_nascita_tcm"
                                        ref="domande_tcm.comune_nascita_tcm"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                            </q-item-section>
                            <q-item-section v-if="domande_tcm.stato_nascita_tcm.value == 'ITALIA'">
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.cap_nascita_tcm)"
                                        :domanda="domande_tcm.cap_nascita_tcm"
                                        ref="domande_tcm.cap_nascita_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                            <q-item-section v-if="domande_tcm.stato_nascita_tcm.value == 'ITALIA'">
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.provincia_nascita_tcm)"
                                        :domanda="domande_tcm.provincia_nascita_tcm"
                                        ref="domande_tcm.provincia_nascita_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_tcm.contraente_uguale_assicurato.value == 'no'">
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.indirizzo_tcm)"
                                        :domanda="domande_tcm.indirizzo_tcm"
                                        ref="domande_tcm.indirizzo_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.comune_tcm)"
                                        :domanda="domande_tcm.comune_tcm"
                                        ref="domande_tcm.comune_tcm"
                                        @update:isValid="onDomandaValidated"
                                        @qq-domanda-change="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.cap_tcm)"
                                        :domanda="domande_tcm.cap_tcm"
                                        ref="domande_tcm.cap_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                        v-if="domandaIsVisible(domande_tcm.provincia_tcm)"
                                        :domanda="domande_tcm.provincia_tcm"
                                        ref="domande_tcm.provincia_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label v-html="getLabelQuestionariFromName('attivita_rischio_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("attivita_rischio_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="getRispostaAnalisiRischioFromName('attivita_rischio_tcm') == 'NO'">
                                <q-item-label caption>{{ getLabelQuestionariFromName("attivita_tcm") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("attivita_tcm")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <legend>QUESTIONARIO ANAMNESTICO “A”</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label v-html="getLabelQuestionariFromName('interrompe_lavoro_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("interrompe_lavoro_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label v-html="getLabelQuestionariFromName('ricoveri_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("ricoveri_tcm")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label v-html="getLabelQuestionariFromName('malattie_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("malattie_tcm")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label v-html="getLabelQuestionariFromName('patologie_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("patologie_tcm")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label v-html="getLabelQuestionariFromName('altro_tcm')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("altro_tcm")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <template v-if="isQuestionarioBTcmAbilitato">
                        <legend>QUESTIONARIO ANAMNESTICO “B”</legend>

                        <q-list separator>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_tcm.attesa_ricovero_tcm)"
                                        :domanda="domande_tcm.attesa_ricovero_tcm"
                                        ref="domande_tcm.attesa_ricovero_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_tcm.esami_diagnostici_tcm)"
                                        :domanda="domande_tcm.esami_diagnostici_tcm"
                                        ref="domande_tcm.esami_diagnostici_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_tcm.malattie_terapia_medica_tcm)"
                                        :domanda="domande_tcm.malattie_terapia_medica_tcm"
                                        ref="domande_tcm.malattie_terapia_medica_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_tcm.attivita_sportive_tcm)"
                                        :domanda="domande_tcm.attivita_sportive_tcm"
                                        ref="domande_tcm.attivita_sportive_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                            <q-item>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_tcm.sostanze_stupefacenti_tcm)"
                                        :domanda="domande_tcm.sostanze_stupefacenti_tcm"
                                        ref="domande_tcm.sostanze_stupefacenti_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                                <q-item-section>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_tcm.inabile_tcm)"
                                        :domanda="domande_tcm.inabile_tcm"
                                        ref="domande_tcm.inabile_tcm"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </template>

                    <legend>DESIGNAZIONE DEI BENEFICIARI</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    v-if="domandaIsVisible(domande_tcm.tipo_beneficiario_tcm)"
                                    :domanda="domande_tcm.tipo_beneficiario_tcm"
                                    ref="domande_tcm.tipo_beneficiario_tcm"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                                <Domanda
                                    v-if="domandaIsVisible(domande_tcm.totale_beneficiari_tcm)"
                                    :domanda="domande_tcm.totale_beneficiari_tcm"
                                    ref="domande_tcm.totale_beneficiari_tcm"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_tcm.tipo_beneficiario_tcm.value == 'elenco_beneficiari'">
                            <q-item-section>
                                <q-list bordered>
                                    <q-item><q-item-section>Inserisci i dati dei Beneficiari</q-item-section></q-item>
                                    <q-item>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.cognome_nome1_tcm)"
                                                :domanda="domande_tcm.cognome_nome1_tcm"
                                                ref="domande_tcm.cognome_nome1_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.data_nascita1_tcm)"
                                                :domanda="domande_tcm.data_nascita1_tcm"
                                                ref="domande_tcm.data_nascita1_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.luogo_nascita1_tcm)"
                                                :domanda="domande_tcm.luogo_nascita1_tcm"
                                                ref="domande_tcm.luogo_nascita1_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.residenza1_tcm)"
                                                :domanda="domande_tcm.residenza1_tcm"
                                                ref="domande_tcm.residenza1_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.relazione1_tcm)"
                                                :domanda="domande_tcm.relazione1_tcm"
                                                ref="domande_tcm.relazione1_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.percentuale_ripartizione1_tcm)"
                                                :domanda="domande_tcm.percentuale_ripartizione1_tcm"
                                                ref="domande_tcm.percentuale_ripartizione1_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="parseInt(domande_tcm.totale_beneficiari_tcm.value) > 1">
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.cognome_nome2_tcm)"
                                                :domanda="domande_tcm.cognome_nome2_tcm"
                                                ref="domande_tcm.cognome_nome2_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.data_nascita2_tcm)"
                                                :domanda="domande_tcm.data_nascita2_tcm"
                                                ref="domande_tcm.data_nascita2_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.luogo_nascita2_tcm)"
                                                :domanda="domande_tcm.luogo_nascita2_tcm"
                                                ref="domande_tcm.luogo_nascita2_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.residenza2_tcm)"
                                                :domanda="domande_tcm.residenza2_tcm"
                                                ref="domande_tcm.residenza2_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.relazione2_tcm)"
                                                :domanda="domande_tcm.relazione2_tcm"
                                                ref="domande_tcm.relazione2_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.percentuale_ripartizione2_tcm)"
                                                :domanda="domande_tcm.percentuale_ripartizione2_tcm"
                                                ref="domande_tcm.percentuale_ripartizione2_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="parseInt(domande_tcm.totale_beneficiari_tcm.value) > 2">
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.cognome_nome3_tcm)"
                                                :domanda="domande_tcm.cognome_nome3_tcm"
                                                ref="domande_tcm.cognome_nome3_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.data_nascita3_tcm)"
                                                :domanda="domande_tcm.data_nascita3_tcm"
                                                ref="domande_tcm.data_nascita3_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.luogo_nascita3_tcm)"
                                                :domanda="domande_tcm.luogo_nascita3_tcm"
                                                ref="domande_tcm.luogo_nascita3_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.residenza3_tcm)"
                                                :domanda="domande_tcm.residenza3_tcm"
                                                ref="domande_tcm.residenza3_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.relazione3_tcm)"
                                                :domanda="domande_tcm.relazione3_tcm"
                                                ref="domande_tcm.relazione3_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.percentuale_ripartizione3_tcm)"
                                                :domanda="domande_tcm.percentuale_ripartizione3_tcm"
                                                ref="domande_tcm.percentuale_ripartizione3_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="parseInt(domande_tcm.totale_beneficiari_tcm.value) > 3">
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.cognome_nome4_tcm)"
                                                :domanda="domande_tcm.cognome_nome4_tcm"
                                                ref="domande_tcm.cognome_nome4_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.data_nascita4_tcm)"
                                                :domanda="domande_tcm.data_nascita4_tcm"
                                                ref="domande_tcm.data_nascita4_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.luogo_nascita4_tcm)"
                                                :domanda="domande_tcm.luogo_nascita4_tcm"
                                                ref="domande_tcm.luogo_nascita4_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.residenza4_tcm)"
                                                :domanda="domande_tcm.residenza4_tcm"
                                                ref="domande_tcm.residenza4_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.relazione4_tcm)"
                                                :domanda="domande_tcm.relazione4_tcm"
                                                ref="domande_tcm.relazione4_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.percentuale_ripartizione4_tcm)"
                                                :domanda="domande_tcm.percentuale_ripartizione4_tcm"
                                                ref="domande_tcm.percentuale_ripartizione4_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                    </q-item>
                                    <q-item v-if="parseInt(domande_tcm.totale_beneficiari_tcm.value) > 4">
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.cognome_nome5_tcm)"
                                                :domanda="domande_tcm.cognome_nome5_tcm"
                                                ref="domande_tcm.cognome_nome5_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.data_nascita5_tcm)"
                                                :domanda="domande_tcm.data_nascita5_tcm"
                                                ref="domande_tcm.data_nascita5_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.luogo_nascita5_tcm)"
                                                :domanda="domande_tcm.luogo_nascita5_tcm"
                                                ref="domande_tcm.luogo_nascita5_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.residenza5_tcm)"
                                                :domanda="domande_tcm.residenza5_tcm"
                                                ref="domande_tcm.residenza5_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.relazione5_tcm)"
                                                :domanda="domande_tcm.relazione5_tcm"
                                                ref="domande_tcm.relazione5_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                        <q-item-section>
                                            <Domanda
                                                v-if="domandaIsVisible(domande_tcm.percentuale_ripartizione5_tcm)"
                                                :domanda="domande_tcm.percentuale_ripartizione5_tcm"
                                                ref="domande_tcm.percentuale_ripartizione5_tcm"
                                                @update:isValid="onDomandaValidated"
                                            />
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-item-section>
                        </q-item>
                        <br>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isTutelaLegaleMedici">
                <fieldset>
                    <legend>Tutela legale settore Medico</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("specializzazione_tl_medici") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("specializzazione_tl_medici")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'"
                                    :domanda="domande_rc_edilizia.motivo_rifiuto_compagnia"
                                    ref="domande_rc_edilizia.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_tutela_medico')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_tutela_medico")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isTutelaLegaleTecnici">
                <fieldset>
                    <legend>Tutela legale settore Tecnico</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("fatturato") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("polizza_in_corso") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                            <q-item-section>
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_schifata")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'">
                                <Domanda
                                    v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_schifata')) === 'Si'"
                                    :domanda="domande_rc_edilizia.motivo_rifiuto_compagnia"
                                    ref="domande_rc_edilizia.motivo_rifiuto_compagnia"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_tutela_tecnico')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_tutela_tecnico")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
            </div>

            <div v-if="isCauzioni">
                <fieldset>
                    <legend>Cauzioni</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipo_rischio_cauzioni") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipo_rischio_cauzioni")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isAsseverazioni">
                <fieldset>
                    <legend>Asseverazioni</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipo_rischio_asseverazioni") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipo_rischio_asseverazioni")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isFideiussioni">
                <fieldset>
                    <legend>Fideiussioni</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipo_rischio_fidejussione") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipo_rischio_fidejussione")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isFotovoltaico">
                <fieldset>
                    <legend>All Risk Fotovoltaico</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costo_impianto_fotovoltaico") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costo_impianto_fotovoltaico")) }} Euro</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("estensioni_fotovoltaico") }} </q-item-label>
                                <q-item-label>
                                    <ul>
                                        <li v-for="(estensione,index) in estensioni_fotovoltaico" v-bind:key="index">
                                            {{estensione.label}}
                                        </li>
                                    </ul>
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_fotovoltaico.indirizzo_fotovoltaico"
                                    ref="domande_fotovoltaico.indirizzo_fotovoltaico"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_fotovoltaico.comune_fotovoltaico"
                                    ref="domande_fotovoltaico.comune_fotovoltaico"
                                    @update:isValid="onDomandaValidated"
                                    @update:domanda="onDomandaChange($event)"
                                    @qq-domanda-change="onDomandaChange($event)"
                                    @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_fotovoltaico.cap_fotovoltaico"
                                    ref="domande_fotovoltaico.cap_fotovoltaico"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_fotovoltaico.provincia_fotovoltaico"
                                    ref="domande_fotovoltaico.provincia_fotovoltaico"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    v-if="domandaIsVisible(domande_fotovoltaico.tipologia_fotovoltaico)"
                                    :domanda="domande_fotovoltaico.tipologia_fotovoltaico"
                                    ref="domande_fotovoltaico.tipologia_fotovoltaico"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    v-if="domandaIsVisible(domande_fotovoltaico.tipo_installazione)"
                                    :domanda="domande_fotovoltaico.tipo_installazione"
                                    ref="domande_fotovoltaico.tipo_installazione"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section v-if="domande_fotovoltaico.tipo_installazione.value === 'tetto'">
                                <Domanda
                                    :domanda="domande_fotovoltaico.altezza_impianto"
                                    ref="domande_fotovoltaico.altezza_impianto"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section v-if="domande_fotovoltaico.tipo_installazione.value === 'tetto'">
                                <Domanda
                                    :domanda="domande_fotovoltaico.numero_piani"
                                    ref="domande_fotovoltaico.numero_piani"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    v-if="domandaIsVisible(domande_fotovoltaico.numero_inverter)"
                                    :domanda="domande_fotovoltaico.numero_inverter"
                                    ref="domande_fotovoltaico.numero_inverter"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    v-if="domandaIsVisible(domande_fotovoltaico.numero_pannelli1)"
                                    :domanda="domande_fotovoltaico.numero_pannelli1"
                                    ref="domande_fotovoltaico.numero_pannelli1"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section v-if="domande_fotovoltaico.numero_inverter.value >= 2">
                                <Domanda
                                    v-if="domandaIsVisible(domande_fotovoltaico.numero_pannelli2)"
                                    :domanda="domande_fotovoltaico.numero_pannelli2"
                                    ref="domande_fotovoltaico.numero_pannelli2"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section v-if="domande_fotovoltaico.numero_inverter.value >= 3">
                                <Domanda
                                    v-if="domandaIsVisible(domande_fotovoltaico.numero_pannelli3)"
                                    :domanda="domande_fotovoltaico.numero_pannelli3"
                                    ref="domande_fotovoltaico.numero_pannelli3"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item >
                            <q-item-section >
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("polizza_in_corso")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                                <q-item-label caption>Con quale Compagnia hai o hai avuto l`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("compagnia")) }} </q-item-label>
                            </q-item-section>
                            <q-item-section v-if="analizzaRisposta(getDomandaQuestionariFromName('polizza_in_corso')) === 'Si'">
                                <q-item-label caption>Data di scadenza dell`ultima copertura assicurativa?</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("scadenza_copertura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_fotovoltaico')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_fotovoltaico")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isDonazioni">
                <fieldset>
                    <legend>Donazioni Immobili</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_donazione") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_donazione")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("valore_donazione") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("valore_donazione")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
                <br><br>
            </div>

            <div v-if="isDroni">
                <fieldset>
                    <legend>SARP Droni</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("totale_droni") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("totale_droni")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costruttore_drone_1") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costruttore_drone_1")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("modello_drone_1") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("modello_drone_1")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_drone_1") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_drone_1")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_drone_1") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_drone_1")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("peso_drone_1") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("peso_drone_1")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 2">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costruttore_drone_2") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costruttore_drone_2")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("modello_drone_2") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("modello_drone_2")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_drone_2") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_drone_2")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_drone_2") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_drone_2")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("peso_drone_2") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("peso_drone_2")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 3">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costruttore_drone_3") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costruttore_drone_3")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("modello_drone_3") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("modello_drone_3")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_drone_3") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_drone_3")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_drone_3") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_drone_3")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("peso_drone_3") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("peso_drone_3")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 4">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costruttore_drone_4") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costruttore_drone_4")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("modello_drone_4") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("modello_drone_4")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_drone_4") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_drone_4")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_drone_4") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_drone_4")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("peso_drone_4") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("peso_drone_4")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 5">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costruttore_drone_5") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costruttore_drone_5")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("modello_drone_5") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("modello_drone_5")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_drone_5") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_drone_5")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_drone_5") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_drone_5")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("peso_drone_5") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("peso_drone_5")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 6">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costruttore_drone_6") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costruttore_drone_6")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("modello_drone_6") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("modello_drone_6")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_drone_6") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_drone_6")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_drone_6") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_drone_6")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("peso_drone_6") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("peso_drone_6")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 7">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costruttore_drone_7") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costruttore_drone_7")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("modello_drone_7") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("modello_drone_7")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_drone_7") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_drone_7")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_drone_7") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_drone_7")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("peso_drone_7") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("peso_drone_7")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 8">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costruttore_drone_8") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costruttore_drone_8")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("modello_drone_8") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("modello_drone_8")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_drone_8") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_drone_8")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_drone_8") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_drone_8")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("peso_drone_8") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("peso_drone_8")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni >= 9">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costruttore_drone_9") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costruttore_drone_9")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("modello_drone_9") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("modello_drone_9")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_drone_9") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_drone_9")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_drone_9") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_drone_9")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("peso_drone_9") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("peso_drone_9")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="totale_droni === 10">
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("costruttore_drone_10") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("costruttore_drone_10")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("modello_drone_10") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("modello_drone_10")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("anno_costruzione_drone_10") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("anno_costruzione_drone_10")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_drone_10") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_drone_10")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("peso_drone_10") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("peso_drone_10")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_droni')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_droni")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
                <br><br>
            </div>

            <div v-if="isCatastrofali">
                <fieldset>
                    <legend>Rischi Catastrofali</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_fabbricato_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_fabbricato_catastrofali")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domandaIsVisible(getDomandaQuestionariFromName('tipo_dimora_catastrofali'))">
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipo_dimora_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipo_dimora_catastrofali")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section v-if="domandaIsVisible(getDomandaQuestionariFromName('tipo_fabbricato_catastrofali'))">
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipo_fabbricato_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipo_fabbricato_catastrofali")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipo_abitazione_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipo_abitazione_catastrofali")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("materiale_costruzione_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("materiale_costruzione_catastrofali")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("metri_quadri_catastrofale") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("metri_quadri_catastrofale")) }} mq</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda=" domande_catastrofali.piani_interrati"
                                    ref="domande_catastrofali.piani_interrati"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda=" domande_catastrofali.piani_fuori_terra"
                                    ref="domande_catastrofali.piani_fuori_terra"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>

                                    <Domanda
                                        :domanda=" domande_catastrofali.indirizzo_catastrofali"
                                        ref="domande_catastrofali.indirizzo_catastrofali"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_catastrofali.comune_catastrofali)"
                                        :domanda="domande_catastrofali.comune_catastrofali"
                                        ref="domande_catastrofali.comune_catastrofali"
                                        @update:isValid="onDomandaValidated"
                                        @update:domanda="onDomandaChange($event)"
                                        @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                    />
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>
                                    <Domanda
                                        v-if="domandaIsVisible(domande_catastrofali.cap_catastrofali)"
                                        :domanda="domande_catastrofali.cap_catastrofali"
                                        ref="domande_catastrofali.cap_catastrofali"
                                        @update:isValid="onDomandaValidated"
                                    />
                                </q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("provincia_catastrofali") }}</q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("provincia_catastrofali")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>

                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-label caption>{{ getLabelQuestionariFromName("sinistri_catastrofali") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_catastrofali")) }} mq</q-item-label>
                        </q-item>

                    </q-list>
                </fieldset>
                <br><br>
            </div>

            <div v-if="isStruttureSanitarie">
                <fieldset>
                    <legend>Strutture Sanitarie</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_struttura_sanitaria") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_struttura_sanitaria")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("struttura_sanitaria_convenzionata") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("struttura_sanitaria_convenzionata")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("fatturato") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_strutture_sanitarie.data_apertura_sanitarie"
                                    ref="domande_strutture_sanitarie.data_apertura_sanitarie"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_strutture_sanitarie.ubicazione_uguale_sede_sanitarie"
                                    ref="domande_strutture_sanitarie.ubicazione_uguale_sede_sanitarie"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section v-if="domande_strutture_sanitarie.ubicazione_uguale_sede_sanitarie.value === 'no'">
                                <Domanda
                                    :domanda="domande_strutture_sanitarie.indirizzo_ubicazione_sanitarie"
                                    ref="domande_strutture_sanitarie.indirizzo_ubicazione_sanitarie"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_strutture_sanitarie.ubicazione_uguale_sede_sanitarie.value === 'no'">
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_strutture_sanitarie.comune_ubicazione_sanitarie"
                                    ref="domande_strutture_sanitarie.comune_ubicazione_sanitarie"
                                    @update:isValid="onDomandaValidated"
                                    @update:domanda="onDomandaChange($event)"
                                    @qq-domanda-change="onDomandaChange($event)"
                                    @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_strutture_sanitarie.cap_ubicazione_sanitarie"
                                    ref="domande_strutture_sanitarie.cap_ubicazione_sanitarie"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_strutture_sanitarie.provincia_ubicazione_sanitarie"
                                    ref="domande_strutture_sanitarie.provincia_ubicazione_sanitarie"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche generali</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-show="!isTaylorMade">Compagnia</q-item-label>
                                <q-item-label v-show="!isTaylorMade">
                                    {{getCompagniaSelezionata}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption v-show="!isTaylorMade">{{garanzia.label}}</q-item-label>
                                <q-item-label v-show="!isTaylorMade">
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                       <q-item>
                            <q-item-section>
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("polizza_in_corso") }} </q-item-label>
                            </q-item-section>
                            <div v-show="getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ">
                                <q-item >
                                    <q-item-section>
                                        <q-item-label caption>Impresa di assicurazione</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('compagnia')}}</q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label caption>Data di scadenza della copertura</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('scadenza_copertura')}}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_struttura_sanitaria')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_struttura_sanitaria")) }} </q-item-label>
                            </q-item-section>
                        </q-item>

                    </q-list>
                </fieldset>
            </div>

            <div v-if="isShengen">
                <fieldset>
                    <legend>Cauzioni Shengen</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_shengen") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_shengen")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("paese_partenza_shengen") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("paese_partenza_shengen")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("data_partenza_shengen") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("data_partenza_shengen")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("paese_destinazione_shengen") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("paese_destinazione_shengen")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("data_rientro_shengen") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("data_rientro_shengen")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Informazioni sulla persona ospitata</legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_shengen.cognome_shengen"
                                    ref="domande_shengen.cognome_shengen"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_shengen.nome_shengen"
                                    ref="domande_shengen.nome_shengen"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_shengen.data_nascita_shengen"
                                    ref="domande_shengen.data_nascita_shengen"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_shengen.numero_passaporto_shengen"
                                    ref="domande_shengen.numero_passaporto_shengen"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche generali</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-show="!isTaylorMade">Compagnia</q-item-label>
                                <q-item-label v-show="!isTaylorMade">
                                    {{getCompagniaSelezionata}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption v-show="!isTaylorMade">{{garanzia.label}}</q-item-label>
                                <q-item-label v-show="!isTaylorMade">
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                </fieldset>
                <br>
            </div>

            <div v-if="isAssociazioni">
                <fieldset>
                    <legend>Associazioni</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("segmento_associazioni") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("segmento_associazioni")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("servizi_svolti_associazioni") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("servizi_svolti_associazioni")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipologia_ente_associazioni") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipologia_ente_associazioni")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_associazioni.numero_associati_associazioni"
                                    ref="domande_associazioni.numero_associati_associazioni"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_associazioni.numero_volontari_associazioni"
                                    ref="domande_associazioni.numero_volontari_associazioni"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_associazioni.numero_dipendenti_associazioni"
                                    ref="domande_associazioni.numero_dipendenti_associazioni"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend> <strong>Caratteristiche generali</strong> </legend>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-show="!isTaylorMade">Compagnia</q-item-label>
                                <q-item-label v-show="!isTaylorMade">
                                    {{getCompagniaSelezionata}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive" v-bind:key="index">
                            <q-item-section>
                                <q-item-label caption v-show="!isTaylorMade">{{garanzia.label}}</q-item-label>
                                <q-item-label v-show="!isTaylorMade">
                                    {{analizzaRisposta(garanzia)}}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                       <q-item>
                            <q-item-section>
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("polizza_in_corso") }} </q-item-label>
                            </q-item-section>
                            <div v-show="getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ">
                                <q-item >
                                    <q-item-section>
                                        <q-item-label caption>Impresa di assicurazione</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('compagnia')}}</q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label caption>Data di scadenza della copertura</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('scadenza_copertura')}}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_struttura_associazioni')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_struttura_associazioni")) }} </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </fieldset>
            </div>

            <div v-if="isMultirischioAgricoltura">
                <fieldset>
                    <legend>Multirischio Agricoltura</legend>
                    <br>
                    <q-list separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("fatturato") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("fatturato")) }} Euro</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("tipo_fabbricato_agricoltura") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("tipo_fabbricato_agricoltura")) }}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("materiale_fabbricato_agricoltura") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("materiale_fabbricato_agricoltura")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.tipologia_contraente_agricoltura"
                                    ref="domande_multirischio_agricoltura.tipologia_contraente_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.valutazione_agricoltura"
                                    ref="domande_multirischio_agricoltura.valutazione_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.allarme_agricoltura"
                                    ref="domande_multirischio_agricoltura.allarme_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.numero_post_letto_agricoltura"
                                    ref="domande_multirischio_agricoltura.numero_post_letto_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.anno_costruzione_agricoltura"
                                    ref="domande_multirischio_agricoltura.anno_costruzione_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.superficie_mq_agricoltura"
                                    ref="domande_multirischio_agricoltura.superficie_mq_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.superficie_ettari_agricoltura"
                                    ref="domande_multirischio_agricoltura.superficie_ettari_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.stabilmente_abitata_agricoltura"
                                    ref="domande_multirischio_agricoltura.stabilmente_abitata_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.numero_soci_agricoltura"
                                    ref="domande_multirischio_agricoltura.numero_soci_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.numero_dipendenti_agricoltura"
                                    ref="domande_multirischio_agricoltura.numero_dipendenti_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.ubicazione_uguale_sede_agricoltura"
                                    ref="domande_multirischio_agricoltura.ubicazione_uguale_sede_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section v-if="domande_multirischio_agricoltura.ubicazione_uguale_sede_agricoltura.value === 'no'">
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.indirizzo_ubicazione_agricoltura"
                                    ref="domande_multirischio_agricoltura.indirizzo_ubicazione_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                        </q-item>
                        <q-item v-if="domande_multirischio_agricoltura.ubicazione_uguale_sede_agricoltura.value === 'no'">
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.comune_ubicazione_agricoltura"
                                    ref="domande_multirischio_agricoltura.comune_ubicazione_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                    @update:domanda="onDomandaChange($event)"
                                    @qq-domanda-change="onDomandaChange($event)"
                                    @qq-domanda-inputSelect-filter="onInputSelectFilter($event)"
                                />
                            </q-item-section>
                            <q-item-section>
                                <Domanda
                                    :domanda="domande_multirischio_agricoltura.cap_ubicazione_agricoltura"
                                    ref="domande_multirischio_agricoltura.cap_ubicazione_agricoltura"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label caption>{{ getLabelQuestionariFromName("provincia_agricoltura") }} </q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("provincia_agricoltura")) }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <legend>Situazione assicurativa</legend>
                    <q-list separator>
                       <q-item>
                            <q-item-section>
                                <q-item-label caption>Hai mai stipulato una polizza per questo rischio?</q-item-label>
                                <q-item-label>{{ getRispostaAnalisiRischioFromName("polizza_in_corso") }} </q-item-label>
                            </q-item-section>
                            <div v-show="getRispostaAnalisiRischioFromName('polizza_in_corso') === 'SI' ">
                                <q-item >
                                    <q-item-section>
                                        <q-item-label caption>Impresa di assicurazione</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('compagnia')}}</q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label caption>Data di scadenza della copertura</q-item-label>
                                        <q-item-label>{{getRispostaAnalisiRischioFromName('scadenza_copertura')}}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label caption v-html="getLabelQuestionariFromName('sinistri_struttura_agricoltura')"></q-item-label>
                                <q-item-label>{{ analizzaRisposta(getDomandaQuestionariFromName("sinistri_struttura_agricoltura")) }} </q-item-label>
                            </q-item-section>
                        </q-item>

                    </q-list>
                </fieldset>
                <br>
            </div>

        </div>
    </div>

</template>

<script>
    import { mapState } from "vuex";
    import commonLib from "@/libs/commonLib";
    import { mapFields } from 'vuex-map-fields';
    import { mapActions } from "vuex";

    import DomandaQuestionarioAssuntivo from "@/components/QuestionariAssuntivi/DomandaQuestionarioAssuntivo";

    export default {
        name: "QQQuestionarioPersoneAziende",
        components: {
           Domanda: DomandaQuestionarioAssuntivo
        },
        data() {
            return {
                domande_caricate: false,
                //options_postuma: ["Si","No"],
                elenco_errori: [],
                totale_errori: 0,
                elenco_stati: [],
                domande_questionario_ricaricate: false
            }
        },

        props: {
            indice_questionario_rischio: {
                type: Number,
                required: false,
                default: 0
            },
        },

        computed: {
            ...mapFields('gestioneQuotazioni', {
                domande_multirischio_casa: "questionari_assuntivi.multirischio_casa.domande",
                domande_infortuni: "questionari_assuntivi.infortuni.domande",
                domande_multirischio_commercio: "questionari_assuntivi.multirischio_commercio.domande",
                domande_multirischio_artigiano: "questionari_assuntivi.multirischio_artigiano.domande",
                domande_globale_fabbricato: "questionari_assuntivi.globale_fabbricato.domande",
                domande_tutela_reddito: "questionari_assuntivi.tutela_reddito.domande",
                domande_veterinaria: "questionari_assuntivi.spese_veterinarie.domande",
                domande_multirischio_albergo: "questionari_assuntivi.multirischio_albergo.domande",
                domande_viaggi: "questionari_assuntivi.viaggi.domande",
                domande_leasing: "questionari_assuntivi.leasing.domande",
                domande_patrimoniale: "questionari_assuntivi.patrimoniale.domande",
                domande_multirischio_ufficio: "questionari_assuntivi.multirischio_ufficio.domande",
                domande_rc_edilizia: "questionari_assuntivi.rc_impresa_edile.domande",
                domande_tcm: "questionari_assuntivi.tcm.domande",
                domande_fotovoltaico: "questionari_assuntivi.fotovoltaico.domande",
                domande_catastrofali: "questionari_assuntivi.catastrofali.domande",
                domande_strutture_sanitarie: "questionari_assuntivi.strutture_sanitarie.domande",
                domande_shengen: "questionari_assuntivi.shengen.domande",
                domande_associazioni: "questionari_assuntivi.associazioni.domande",
                domande_multirischio_agricoltura: "questionari_assuntivi.multirischio_agricoltura.domande"
            }),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                analisi_necessita: state => state.formPreventivo.preventivo.analisi_necessita,
                analisi_rischio: state => state.formPreventivo.preventivo.analisi_rischio,
                dati_cliente: state => state.formPreventivo.dati_cliente,
                is_loading: state => state.formPreventivo.is_loading,
                tutti_i_comuni: state => state.geoStore.comuni
            }),

            isTaylorMade() {
                return false;
                //if (commonLib.isEmpty(this.formPreventivo.prodotti)) return false;

                //return this.formPreventivo.preventivo.prodotti[0].is_tailormade;
            },

            totale_droni() {
                console.log("totale droni:",parseInt(this.getDomandaQuestionariFromName("totale_droni").value));
                return parseInt(this.getDomandaQuestionariFromName("totale_droni").value);
            },

            estensioni_fotovoltaico() {
                let domanda_attivita_aggiuntive =
                    this.getDomandaQuestionariFromName("estensioni_fotovoltaico");

                // se non trova la domanda nell'analisi rischio
                if (commonLib.isNull(domanda_attivita_aggiuntive)) {
                    return [];
                }

                let risposte = domanda_attivita_aggiuntive.value;
                let attivita = domanda_attivita_aggiuntive.options
                    .filter(opt => {
                        return risposte.includes(opt.value)
                });

                return attivita;
            },

            isMultirischioCasa() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;
                console.log("prodotto identificato:",tariffa,indice);
                return (tariffa === "multirischio_casa");
            },
            isInfortuni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "infortuni");
            },
            isMultirischioCommercio() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_commercio");
            },
            isMultirischioArtigiano() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_artigiano");
            },
            isMultirischioAlbergo() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_albergo");
            },
            isGlobaleFabbricato() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "globale_fabbricato");
            },
            isPolizzaVirus() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "virus");
            },
            isTutelaReddito() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "tutela_reddito");
            },
            isVeterinaria() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "spese_veterinarie");
            },
            isDeO() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "deo");
            },
            isPolizzaViaggi() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                console.log("prodotto:",prodotti);

                return (tariffa === "viaggi");
            },
            isPolizzaLeasing() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "leasing");
            },
            isPatrimoniale() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "patrimoniale");
            },
            isMultirischioUfficio() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_ufficio");
            },
            isRCEdilizia() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "rc_impresa_edile");
            },
            isTCM() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "tcm");
            },
            isTutelaLegaleMedici() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "tutela_legale_medici");
            },

            isTutelaLegaleTecnici() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "tutela_legale_professionisti");
            },

            isCauzioni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "cauzioni");
            },

            isAsseverazioni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "asseverazioni");
            },

            isFideiussioni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "fideiussione");
            },

            isFotovoltaico() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                console.log("tariffa:",tariffa);

                return (tariffa === "fotovoltaico");
            },

            isDonazioni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                console.log("tariffa:",tariffa);

                return (tariffa === "donazioni");
            },
            isDroni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "droni");
            },
            isCatastrofali() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "catastrofali");
            },
            isStruttureSanitarie() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "strutture_sanitarie");
            },
            isShengen() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "shengen");
            },
            isAssociazioni() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "associazioni");
            },
            isMultirischioAgricoltura() {
                var indice = this.getIndiceQuestionarioRischio;
                var prodotti = this.prodotti_attivi;
                var prodotto = prodotti[indice];
                var tariffa = prodotto.id;

                return (tariffa === "multirischio_agricoltura");
            },

            isQuestionarioBTcmAbilitato() {
                var eta_assicurato = this.getRispostaAnalisiRischioFromName("eta_tcm");
                var capitale_assicurato = parseFloat(this.getRispostaAnalisiRischioFromName("capitale_tcm"));

                // Se ha risposto SI almeno ad una domanda del questionario A allora lo devo visualizzare a prescindere dal resto
                var dati_questionario_A = ((this.getRispostaAnalisiRischioFromName("interrompe_lavoro_tcm") === "SI") ||
                                    (this.getRispostaAnalisiRischioFromName("ricoveri_tcm") === "SI") ||
                                    (this.getRispostaAnalisiRischioFromName("malattie_tcm") === "SI") ||
                                    (this.getRispostaAnalisiRischioFromName("patologie_tcm") === "SI") ||
                                    (this.getRispostaAnalisiRischioFromName("altro_tcm") === "SI")
                                   );

                if (dati_questionario_A) return true;

                switch(eta_assicurato) {
                    case "0_45":
                    case "46_50":
                        if ((capitale_assicurato > 200000) && (capitale_assicurato <= 500000)) return true;
                    break;
                    case "51_55":
                    case "56_60":
                        if ((capitale_assicurato > 100000) && (capitale_assicurato <= 300000)) return true;
                    break;
                    case "61_65":
                        if (capitale_assicurato <= 100000) return true;
                    break;
                    case "66_70":
                        if (capitale_assicurato <= 75000) return true;
                    break;

                }

                return false;
            },
            prodotti_selezionati() {
                return this.formPreventivo.preventivo.prodotti.filter(p => {
                    return p.is_checked && (p.is_taylormade || p.is_taylor_made);
                });
            },

            prodotti_attivi() {
                return this.formPreventivo.preventivo.prodotti.filter(p => {
                    return p.is_checked;
                });
            },

            getElencoGaranzieAggiuntive() {
                if (commonLib.isEmpty(this.formPreventivo)) return [];

                var prodotto = this.prodotti_selezionati[this.indice_questionario_rischio];
                //console.log("prodotto:",prodotto);
                var proposte = prodotto.proposte;
                // Cerca quella selezionata
                var proposta = proposte.find(p => {
                    return p.is_checked;
                });

                //console.log("proposta:",proposta,"pippo");

                return proposta.parametri;
            },

            getIndiceQuestionarioRischio() {
                var prodotto_id = this.prodotti_selezionati[this.indice_questionario_rischio].id;
                var indice = 0;

                console.log("prodotti selezionati:",this.prodotti_selezionati);

                for (var i=0;i<this.prodotti_attivi.length;i++) {
                    if (prodotto_id === this.prodotti_attivi[i].id) indice = i;
                }

                return indice;
            },

            getCompagniaSelezionata() {
                var result = "";
                if (commonLib.isEmpty(this.formPreventivo)) return result;


                var prodotto = this.prodotti_selezionati[this.indice_questionario_rischio];
                var proposte = prodotto.proposte;
                // Cerca quella selezionata
                var proposta = proposte.find(p => {
                    return p.is_checked;
                });

                //console.log("proposta:",proposta);

                if (!commonLib.isEmpty(proposta))
                    result =  proposta.compagnia;


            /*    for (var i=0;i< this.formPreventivo.preventivo.prodotti.length;i++) {
                    var prodotto = this.formPreventivo.preventivo.prodotti[i];
                    if ((prodotto.label === "RC Settore Medico") &&
                        (prodotto.is_checked)) {
                        var proposte = prodotto.proposte.filter(proposta => {
                            return proposta.is_checked;
                        });


                        if (!commonLib.isEmpty(proposte[0]))
                            result =  proposte[0].compagnia;

                            //console.log("proposte:",proposte);
                    }
                } */

                return result;
            },
            totale_animali() {
                if (this.isVeterinaria) {
                    var lista = this.analizzaRisposta(this.getDomandaQuestionariFromName('totale_animali_veterinaria')).split(" ");
                    return parseInt(lista[0]);
                }

                return 0;
            }
        },
        methods: {
            ...mapActions({
                loadQuestionariAssuntivi: "gestioneQuotazioni/loadQuestionariAssuntivi",
                resetQuestionariAssuntivi: "gestioneQuotazioni/resetQuestionariAssuntivi",
                getGeoComuniForProvincia: "formPreventivo/getGeoComuniForProvincia",
                getGeoComuniForName: "formPreventivo/getGeoComuniForName",
                getGeoCapForComune: "formPreventivo/getGeoCapForComune",
                getStatiFormField: "formPreventivo/getGeoStatiForFormField",
                getProvinciaFromComune: "formPreventivo/getProvinciaFromComune"
            }),
            async onMapComuni(comuni) {
                if (commonLib.isEmpty(comuni)) return [];

                return comuni.map(comune => {
                    let nome = comune.nome.trim();
                    return {
                        is_visible_if:"",
                        label: nome,
                        value: nome
                    };
                });
            },
            async onMapCap(cap) {
                if (commonLib.isEmpty(cap)) return [];

                return await cap.map(c => {
                    return {
                        is_visible_if:"",
                        label: c.trim(),
                        value: c.trim()
                    };
                });
            },
            onPopolaStati() {
                //console.log("elenco stati:",this.elenco_stati,this.isInfortuni);
                if (this.isInfortuni) {
                    this.domande_infortuni.stato_nascita_infortuni.options = this.elenco_stati;
                }
                if (this.isPatrimoniale) {
                    this.domande_patrimoniale.stato_nascita_patrimoniale.options = this.elenco_stati;
                }
                if (this.isTCM) {
                    this.domande_tcm.stato_nascita_tcm.options = this.elenco_stati;
                }
            },
            async onChangeComuneResidenzaInfortuni(input_value,domanda) {
                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                // Cerca il CAP degli infortuni
                var dom_cap_infortuni = this.domande_infortuni.cap_infortuni;

                if (comune_selezionato.length > 0) {
                    dom_cap_infortuni.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap_infortuni.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                        dom_cap_infortuni.value = comune_selezionato[0].cap[0];

                    let dom_provincia_infortuni = this.domande_infortuni.provincia_infortuni;

                    dom_provincia_infortuni.value =
                        comune_selezionato[0].sigla_provincia;
                }

            },
            async onChangeComuneResidenzaPatrimoniale(input_value,domanda) {
                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                // Cerca il CAP
                var dom_cap = this.domande_patrimoniale.cap_patrimoniale;

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap.value = comune_selezionato[0].cap[0];

                    let dom_provincia = this.domande_patrimoniale.provincia_patrimoniale;

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }

            },
            async onChangeComuneNascitaInfortuni(input_value,domanda) {
                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                //console.log("domanda onChangeComuneNascitaInfortuni:",domanda);
               // console.log("elenco_mappato:",elenco_mappato);

                // Cerca il CAP degli infortuni
                var dom_cap_infortuni = this.domande_infortuni.cap_nascita_infortuni;

                if (comune_selezionato.length > 0) {
                    dom_cap_infortuni.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap_infortuni.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap_infortuni.value = comune_selezionato[0].cap[0];

                    let dom_provincia_infortuni = this.domande_infortuni.provincia_nascita_infortuni;

                    dom_provincia_infortuni.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },
            async onChangeComuneNascitaPatrimoniale(input_value,domanda) {
                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                // Cerca il CAP
                var dom_cap = this.domande_patrimoniale.cap_nascita_patrimoniale;

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap.value = comune_selezionato[0].cap[0];

                    let dom_provincia = this.domande_patrimoniale.provincia_nascita_patrimoniale;

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }

            },
            async onChangeComuneSedeInfortuni(input_value,domanda) {
                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                //console.log("domanda:",domanda);
                //console.log("elenco_mappato:",elenco_mappato);

                // Cerca il CAP degli infortuni
                var dom_cap_infortuni = this.domande_infortuni.cap_sede_infortuni;

                if (comune_selezionato.length > 0) {
                    dom_cap_infortuni.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap_infortuni.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap_infortuni.value = comune_selezionato[0].cap[0];

                    let dom_provincia_infortuni = this.domande_infortuni.provincia_sede_infortuni;

                    dom_provincia_infortuni.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },
            async onChangeCapComuneCasa(input_value) {
                input_value = input_value.trim();

            /*    var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));*/

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var dom_cap_casa = this.domande_multirischio_casa.cap_casa;

                if (comune_selezionato.length > 0) {
                    dom_cap_casa.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap_casa.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap_casa.value = comune_selezionato[0].cap[0];
                }
            },
            async onChangeCapComuneCatastrofali(input_value) {
                input_value = input_value.trim();

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var dom_cap_casa = this.domande_catastrofali.cap_catastrofali;

                if (comune_selezionato.length > 0) {
                    dom_cap_casa.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap_casa.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap_casa.value = comune_selezionato[0].cap[0];
                }
            },
            async onChangeCapComuneUfficio(input_value) {
                input_value = input_value.trim();

            /*    var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));*/

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var dom_cap = this.domande_multirischio_ufficio.cap_ufficio;

                //console.log("dom_cap:",dom_cap);

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap.value = comune_selezionato[0].cap[0];
                }
            },
            async onChangeComuneCommercio(input_value,domanda) {
                input_value = input_value.trim();

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                var dom_cap_commercio = this.domande_multirischio_commercio.cap_commercio;

                if (comune_selezionato.length > 0) {
                    dom_cap_commercio.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap_commercio.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap_commercio.value = comune_selezionato[0].cap[0];

                    let dom_provincia_commercio = this.domande_multirischio_commercio.provincia_commercio;

                    dom_provincia_commercio.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },
            async onChangeComuneArtigiano(input_value,domanda) {
                input_value = input_value.trim();

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                var dom_cap_artigiano = this.domande_multirischio_artigiano.cap_artigiano;

                if (comune_selezionato.length > 0) {
                    dom_cap_artigiano.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap_artigiano.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap_artigiano.value = comune_selezionato[0].cap[0];

                    let dom_provincia_artigiano = this.domande_multirischio_artigiano.provincia_artigiano;

                    dom_provincia_artigiano.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },
            async onChangeComuneAlbergo(input_value,domanda) {
                input_value = input_value.trim();

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                var dom_cap = this.domande_multirischio_albergo.cap_albergo;

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap.value = comune_selezionato[0].cap[0];

                    let dom_provincia = this.domande_multirischio_albergo.provincia_albergo;

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },
            async onChangeComuneNascitaViaggio(index,input_value,domanda) {
                input_value = input_value.trim();

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                if (comune_selezionato.length > 0) {
                    let dom_provincia = this.domande_viaggi['provincia_nascita'+index];

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },
            async onChangeComuneNascitaTcm(input_value,domanda) {
                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                var dom_cap = this.domande_tcm.cap_nascita_tcm;

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap.value = comune_selezionato[0].cap[0];

                    let dom_provincia = this.domande_tcm.provincia_nascita_tcm;

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }

            /*    if (comune_selezionato.length > 0) {
                    let dom_provincia = this.domande_tcm['provincia_nascita_tcm'];

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                } */
            },
            async onChangeComuneResidenzaViaggio(index,input_value,domanda) {
                input_value = input_value.trim();

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                var dom_cap = this.domande_viaggi["cap"+index];

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap.value = comune_selezionato[0].cap[0];

                    let dom_provincia = this.domande_viaggi["provincia"+index];

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },
            async onChangeComuneCondominio(input_value,domanda) {
                input_value = input_value.trim();

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                var dom_cap = this.domande_globale_fabbricato.cap_condominio;

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap.value = comune_selezionato[0].cap[0];

                    let dom_provincia = this.domande_globale_fabbricato.provincia_condominio;

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },
            async onChangeComuneTcm(input_value,domanda) {
                input_value = input_value.trim();

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                var dom_cap = this.domande_tcm.cap_tcm;

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap.value = comune_selezionato[0].cap[0];

                    let dom_provincia = this.domande_tcm.provincia_tcm;

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },
            async onChangeComuneLeasing(input_value,domanda) {
                input_value = input_value.trim();

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                var dom_cap = this.domande_leasing.cap_leasing;

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                    dom_cap.value = comune_selezionato[0].cap[0];

                    let dom_provincia = this.domande_leasing.provincia_leasing;

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }

            },
            async onChangeComuneFotovoltaico(input_value,domanda) {
                input_value = input_value.trim();

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                    // return comune.nome.toLowerCase().includes(input_value.toLowerCase());
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                var dom_cap = this.domande_fotovoltaico.cap_fotovoltaico;

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                        dom_cap.value = comune_selezionato[0].cap[0];

                    let dom_provincia = this.domande_fotovoltaico.provincia_fotovoltaico;

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }

            },
            async onChangeComuneSanitarie(input_value,domanda) {
                input_value = input_value.trim();

                var elenco = (this.tutti_i_comuni.filter(comune => {
                    var len = input_value.length;
                    var start_with = comune.nome.substring(0,len).toLowerCase();
                    return start_with === input_value.toLowerCase();
                }));

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                    // return comune.nome.toLowerCase().includes(input_value.toLowerCase());
                });

                var elenco_mappato = await this.onMapComuni(elenco);
                domanda.options = elenco_mappato;

                var dom_cap = this.domande_strutture_sanitarie.cap_ubicazione_sanitarie;

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                        dom_cap.value = comune_selezionato[0].cap[0];

                    let dom_provincia = this.domande_strutture_sanitarie.provincia_ubicazione_sanitarie;

                    dom_provincia.value =
                        comune_selezionato[0].sigla_provincia;
                }
            },
            async onChangeComuneAgricoltura(input_value) {
                input_value = input_value.trim();

                var comune_selezionato = this.tutti_i_comuni.filter(comune => {
                    return comune.nome.toLowerCase() === input_value.toLowerCase();
                });

                var dom_cap = this.domande_multirischio_agricoltura.cap_ubicazione_agricoltura;

                if (comune_selezionato.length > 0) {
                    dom_cap.options =
                        await this.onMapCap(comune_selezionato[0].cap);

                    dom_cap.value = "";
                    if (comune_selezionato[0].cap.length === 1)
                        dom_cap.value = comune_selezionato[0].cap[0];
                }
            },

            async onDomandaChange(domanda) {
                var index = 0;

                switch (domanda.name) {
                    case "comune_infortuni":
                        await this.onChangeComuneResidenzaInfortuni(domanda.value,domanda);
                    break;
                    case "comune_patrimoniale":
                        await this.onChangeComuneResidenzaPatrimoniale(domanda.value,domanda);
                    break;
                    case "comune_nascita_infortuni":
                        await this.onChangeComuneNascitaInfortuni(domanda.value,domanda);
                    break;
                    case "comune_nascita_patrimoniale":
                        await this.onChangeComuneNascitaPatrimoniale(domanda.value,domanda);
                    break;
                    case "comune_nascita_tcm":
                        await this.onChangeComuneNascitaTcm(domanda.value,domanda);
                    break;
                    case "comune_tcm":
                        await this.onChangeComuneTcm(domanda.value,domanda);
                    break;
                    case "comune_sede_infortuni":
                        await this.onChangeComuneSedeInfortuni(domanda.value,domanda);
                    break;
                    case "comune_casa":
                        await this.onChangeCapComuneCasa(domanda.value);
                    break;
                    case "comune_catastrofali":
                        await this.onChangeCapComuneCatastrofali(domanda.value);
                    break;
                    case "comune_ufficio":
                    await this.onChangeCapComuneUfficio(domanda.value);
                    break;
                    case "comune_commercio":
                        await this.onChangeComuneCommercio(domanda.value,domanda);
                    break;
                    case "comune_artigiano":
                        await this.onChangeComuneArtigiano(domanda.value,domanda);
                    break;
                    case "comune_condominio":
                        await this.onChangeComuneCondominio(domanda.value,domanda);
                    break;
                    case "comune_albergo":
                        await this.onChangeComuneAlbergo(domanda.value,domanda);
                    break;
                    case "comune_nascita1":
                    case "comune_nascita2":
                    case "comune_nascita3":
                    case "comune_nascita4":
                    case "comune_nascita5":
                    case "comune_nascita6":
                    case "comune_nascita7":
                    case "comune_nascita8":
                    case "comune_nascita9":
                    case "comune_nascita10":
                        index = parseInt(domanda.name.replace("comune_nascita",""));
                        await this.onChangeComuneNascitaViaggio(index,domanda.value,domanda);
                    break;
                    case "comune1":
                    case "comune2":
                    case "comune3":
                    case "comune4":
                    case "comune5":
                    case "comune6":
                    case "comune7":
                    case "comune8":
                    case "comune9":
                    case "comune10":
                        index = parseInt(domanda.name.replace("comune",""));
                        await this.onChangeComuneResidenzaViaggio(index,domanda.value,domanda);
                    break;
                    case "comune_leasing":
                        await this.onChangeComuneLeasing(domanda.value,domanda);
                    break;
                    case "comune_fotovoltaico":
                        await this.onChangeComuneFotovoltaico(domanda.value,domanda);
                    break;
                    case "comune_ubicazione_sanitarie":
                        await this.onChangeComuneSanitarie(domanda.value,domanda);
                    break;
                    case "comune_ubicazione_agricoltura":
                        await this.onChangeComuneAgricoltura(domanda.value,domanda);
                    break;
                }
            },
            async onInputSelectFilter($event) {
                let input_value = $event.input_value;
                let domanda = $event.domanda;
                let update = $event.update;
                let abort = $event.abort;
                let estensione = domanda.name;
                let index = 0;

                if(input_value.length < 3) { // 3
                    abort();
                    return;
                }

                switch(estensione) {
                    case "comune_infortuni":
                        update(async ()=>{
                            await this.onChangeComuneResidenzaInfortuni(input_value,domanda);
                        });
                    break;
                    case "comune_patrimoniale":
                        update(async ()=>{
                            await this.onChangeComuneResidenzaPatrimoniale(input_value,domanda);
                        });
                    break;
                    case "comune_nascita_infortuni":
                        update(async ()=>{
                            await this.onChangeComuneNascitaInfortuni(input_value,domanda);
                        });
                    break;
                    case "comune_nascita_patrimoniale":
                        update(async ()=>{
                            await this.onChangeComuneNascitaPatrimoniale(input_value,domanda);
                        });
                    break;
                    case "comune_nascita_tcm":
                        update(async ()=>{
                            await this.onChangeComuneNascitaTcm(input_value,domanda);
                        });
                    break;
                    case "comune_tcm":
                        update(async ()=>{
                            await this.onChangeComuneTcm(input_value,domanda);
                        });
                    break;
                    case "comune_sede_infortuni":
                        update(async ()=>{
                            await this.onChangeComuneSedeInfortuni(input_value,domanda);
                        });
                    break;
                    case "comune_commercio":
                        update(async ()=>{
                            await this.onChangeComuneCommercio(input_value,domanda);
                        });
                    break;
                    case "comune_artigiano":
                        update(async ()=>{
                            await this.onChangeComuneArtigiano(input_value,domanda);
                        });
                    break;
                    case "comune_condominio":
                        update(async ()=>{
                            await this.onChangeComuneCondominio(input_value,domanda);
                        });
                    break;
                    case "comune_albergo":
                        update(async ()=>{
                            await this.onChangeComuneAlbergo(input_value,domanda);
                        });
                    break;
                    case "comune_nascita1":
                    case "comune_nascita2":
                    case "comune_nascita3":
                    case "comune_nascita4":
                    case "comune_nascita5":
                    case "comune_nascita6":
                    case "comune_nascita7":
                    case "comune_nascita8":
                    case "comune_nascita9":
                    case "comune_nascita10":
                        index = parseInt(domanda.name.replace("comune_nascita",""));
                        update(async ()=>{
                            await this.onChangeComuneNascitaViaggio(index,input_value,domanda);
                        });
                    break;
                    case "comune1":
                    case "comune2":
                    case "comune3":
                    case "comune4":
                    case "comune5":
                    case "comune6":
                    case "comune7":
                    case "comune8":
                    case "comune9":
                    case "comune10":
                        index = parseInt(domanda.name.replace("comune",""));
                        update(async ()=>{
                            await this.onChangeComuneResidenzaViaggio(index,input_value,domanda);
                        });
                    break;
                    case "comune_leasing":
                        update(async ()=>{
                            await this.onChangeComuneLeasing(input_value,domanda);
                        });
                    break;
                    case "comune_fotovoltaico":
                        update(async ()=>{
                            await this.onChangeComuneFotovoltaico(input_value,domanda);
                        });
                    break;
                    case "comune_ubicazione_sanitarie":
                        update(async ()=>{
                            await this.onChangeComuneSanitarie(input_value,domanda);
                        });
                    break;
                    case "comune_ubicazione_agricoltura":
                        update(async () => {
                            await this.onChangeComuneAgricoltura(input_value,domanda);
                        });
                    break;
                }
            },
            analizzaRisposta(garanzia) {
                // type,value,options

                if (commonLib.isEmpty(garanzia)) return [];

                let result = "";
                let value = garanzia.value;
                let options = garanzia.options;
                let type = garanzia.type;

                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                });

                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);
                    return result.replace("_"," ");
                } else {
                    if (value !== null) {
                        if (type === "checkbox") return garanzia.is_checked ? "SI" : "NO";

                        return value.replace("_"," ");
                    } else
                        return "";
                }
            },
            domandeRefs() {
                let refs = Object.entries(this.$refs)
                    .filter(entry => entry[0].indexOf("domande.")===0);

                refs = Object.fromEntries(refs);

                let refs_puliti = {};
                Object.keys(refs).map((key) => {
                    refs_puliti[key] = refs[key]
                    if(Array.isArray(refs[key])) {
                        refs_puliti[key] = refs[key][0];
                    }
                });

                return refs_puliti;
            },

            havePolizzaStessoRischio() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }

                return this.getRispostaAnalisiRischioFromName('polizza_in_corso');
            },

            PolizzaSchifataDaCompagnia() {
                let value = this.getRispostaAnalisiRischioFromName("polizza_schifata");

                return value.toUpperCase();
            },

            isGruppoDomande(domanda) {
                if (commonLib.isEmpty(domanda)) return false;
                for (const key in domanda) {
                    //console.log("isGruppoDomande:",domanda,key,domanda[key],typeof(domanda[key]));
                    var isObject = typeof(domanda[key]) === "object";
                    //console.log("isObject:",isObject);
                    return isObject;
                }

                return false;
            },
            controllaDomanda(domanda) {
                if (commonLib.isEmpty(domanda)) return true;
                if (this.isGruppoDomande(domanda)) return this.controllaGruppoDomande(domanda);
                if (typeof(domanda) !== "object") return true;

                // controlla se domanda è visibile
                // se non visibile, è valida perchè non serve validazione
                if (domanda.is_visible_if !== "") {
                    // console.log("Rifiuto_compagnia",this.domande.rifiuto_compagnia.value);
                    if (!this.condizioneIsValida(domanda.is_visible_if)) {
                        // console.log("Campo non visibile");
                        return true; // Vuol dire che non e` visibile quindi esce senza fare altri controlli
                    }
                }

                // domanda visibile, fai validazione
                return true;
                //domanda.is_valid;

                // if (domanda.validation === "") return true; // Se non ci sono controlli di validazione esce senza errori

                // // splitta tutti i comandi di verifica
                // let attributi = domanda.validation.split("|");
                // let {Messaggio,esito} = validation.verificaRegole(attributi,domanda.value);

                // if (domanda.label === "Breve descrizione dei motivi del rifiuto") {
                //     console.log("Esito verifica:",domanda.label, domanda.value, attributi, Messaggio, esito, domanda.is_visible_if);
                // }

                // if (esito)
                //     console.log("Situazione verifica ",domanda.name,attributi,domanda.value,esito,Messaggio);

                // //console.log("Messaggio:",Messaggio);

                // return !esito;
            },
            controllaGruppoDomande(domande) {
                let result = true;
                for (const key in domande) {
                    if (typeof(domande[key]) === "object")
                        result = result && this.controllaDomanda(domande[key]);
                }

                return result;
            },
            async isQuestionarioValid() {
                var questionario_is_valid = this.elenco_errori.length === 0;

                console.debug("isQuestionarioValid",questionario_is_valid);
                this.$emit("update:isValid", questionario_is_valid);

                return questionario_is_valid;
            },
            async onDomandaValidated({isValid, domanda})
            {
                if (commonLib.isEmpty(domanda)) return;

                if (this.isPolizzaVirus) {
                    //console.log("Maremma cane sono qui");
                    return true;
                }

                this.elenco_errori =
                    this.elenco_errori.filter(d => d !== domanda.name);

                // domanda visibile e con errore
                if(this.domandaIsVisible(domanda) && !isValid) {
                    console.debug("onDomandaValidated", domanda.name, isValid);
                    this.elenco_errori.push(domanda.name);
                }

                await this.isQuestionarioValid();
            },
            resultDomande() {
                return null;
            },
            getValueFromNameCliente(name) {
                if (commonLib.isEmpty(this.formPreventivo)) return "";

                var dati_cliente = this.formPreventivo.dati_cliente[0].domande;
                var result = dati_cliente.find(dato => {
                    return dato.name === name;
                });

                if (commonLib.isEmpty(result.value)) return "";

                return result.value.toUpperCase();
            },
            getRispostaAnalisiNecessitafromName(name) {
                if (!commonLib.isEmpty(this.analisi_necessita)) {
                    var domanda = this.analisi_necessita.find(d => {
                        return d.name === name;
                    });

                    // Se la domanda non e` presente nel questionario allora domanda=undefined
                    if (commonLib.isEmpty(domanda)) return "";

                    return domanda.value.toUpperCase();
                }

                return "";
            },

            getDomandaAnalisiRischioFromName(name) {
                // analisi rischio RC sempre in posizione 0
                // TODO: andare per id

                // non c'è analisi rischio
                if (commonLib.isEmpty(this.analisi_rischio))
                    return null;

                //console.log("getDomandaAnalisiRischioFromName=>indice_prodotto:",this.indice_questionario_rischio);


                // non ci sono domande analisi rischio
                let indice_questionario = this.getIndiceQuestionarioRischio;
                let analisi_rischio_rc = this.analisi_rischio[indice_questionario];

                //console.log("indice questionario:",indice_questionario);
                //console.log("analisi rischio:",analisi_rischio_rc);

                if (commonLib.isEmpty(analisi_rischio_rc)) {
                    return null;
                }

                // cerca la domanda
                var domanda = analisi_rischio_rc.domande.find(d => {
                    return d.name === name;
                });

                // domanda non trovata
                if(commonLib.isUndefined(domanda)) {
                    return null;
                }

                return domanda;
            },

            getDomandaAnalisiNecessitaFromName(name) {
                // non c'è analisi necessita
                if (commonLib.isEmpty(this.analisi_necessita))
                    return null;

                // cerca la domanda
                var domanda = this.analisi_necessita.find(d => {
                    return d.name === name;
                });

                // domanda non trovata
                if(commonLib.isUndefined(domanda)) {
                    return null;
                }

                return domanda;
            },

            getRispostaAnalisiRischioFromName(name) {
                if (commonLib.isEmpty(this.analisi_rischio)) return "";

                // let analisi_rischio_rc = this.analisi_rischio[0];
                let indice_questionario = this.getIndiceQuestionarioRischio;
                let analisi_rischio_rc = this.analisi_rischio[indice_questionario];

                //console.log("analisi rischio completo:",this.analisi_rischio,name);

                if (!commonLib.isEmpty(analisi_rischio_rc)) {
                    var domanda = analisi_rischio_rc.domande.find(d => {
                        return d.name === name;
                    });

                    //console.log("domanda:",domanda);

                    if (commonLib.isEmpty(domanda)) return "";

                    var risposta =  domanda.value;

                    if (!Array.isArray(risposta))
                        return risposta.toUpperCase();

                    return risposta;
                }

                return "";

            },

            getRispostaQuestionariFromName(name) {
                var risposta = this.getRispostaAnalisiRischioFromName(name);
                if (commonLib.isEmpty(risposta)) risposta = this.getRispostaAnalisiNecessitafromName(name);

                return risposta;
            },
            getDomandaQuestionariFromName(name) {
                var domanda = this.getDomandaAnalisiRischioFromName(name);
                if (commonLib.isEmpty(domanda)) domanda = this.getDomandaAnalisiNecessitaFromName(name);

                return domanda;
            },
            getLabelQuestionariFromName(name) {
                var obj = this.getDomandaQuestionariFromName(name);
                if (commonLib.isEmpty(obj)) return "Campo non trovato: "+name;
                return obj.label;
            },


            getCurrentYear(decresci) {
                var data = new Date();
                return "Consuntivo "+(data.getFullYear() - decresci);
            },

            condizioneIsValidaInDomanda(condizione, domanda)
            {
                // Recupera i nome del campo di riferimento
                // let NomeCampo = condizione.split(":")[0];
                let ValoreCampo = condizione.split(":")[1];

                if (commonLib.isEmpty(domanda)) return true;

                // la domanda a cui la condizione fa riferimento non è visibile
                if(!this.domandaIsVisible(domanda)) {
                    //if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                    //    console.log("condizioneIsValida.perc_anno_corrente !domandaIsVisible");
                    //}
                    return false;
                }

                // il value non è valorizzato
                if(commonLib.isNullOrEmpty(domanda.value)) {
                    //if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                    //    console.log("condizioneIsValida.perc_anno_corrente isNullOrEmpty(domanda.value)");
                    //}
                    return false;
                }

                // la risposta è una stringa, ci basta controllare che la
                // condizione contenga la stringa
                // es. risposta "ciccio", condizione "ciccio,pasticcio,mario"
                if(typeof domanda.value === "string") {
                    return ValoreCampo.includes(domanda.value);
                }

                // se la risposta alla domanda è un array, controlliamo che
                // la condizione sia contenuta nell'array di risposta

                // ValoreCampo = val1,val2,val3,val4
                // domanda.value = ["val5", "val1"]
                if(ValoreCampo.indexOf(",") > 1) {
                    var elenco = ValoreCampo.Split(",");
                    var result = true;
                    elenco.forEach(item => {
                        result &= domanda.value.includes(item);
                    });

                    return result;
                }

                return domanda.value.includes(ValoreCampo);
            },
            getDomandeCorrenti() {
                if (this.isMultirischioCasa) return this.domande_multirischio_casa;
                if (this.isInfortuni) return this.domande_infortuni;
                if (this.isMultirischioAlbergo) return this.domande_multirischio_albergo;
                if (this.isMultirischioArtigiano) return this.domande_multirischio_artigiano;
                if (this.isVeterinaria) return this.domande_veterinaria;
                if (this.isTutelaReddito) return this.domande_tutela_reddito;
                if (this.isPolizzaVirus) return this.domande_polizza_virus;
                if (this.isGlobaleFabbricato) return this.domande_globale_fabbricato;
                if (this.isPolizzaViaggio) return this.domande_viaggi;
                if (this.isPolizzaLeasing) return this.domande_leasing;
                if (this.isTCM) return this.domande_tcm;

                return {}
            },

            // interpreta le singoli condizioni di is_visible_if
            // esempio is_visible_if:"lavoro:medico"
            // condizione "lavoro:medico"
            condizioneIsValida(condizione)
            {
                // Recupera l'elenco delle domande legate al prodotto selezionato
                let domande = this.getDomandeCorrenti();

                // Recupera i nome del campo di riferimento
                let NomeCampo = condizione.split(":")[0];
                // let ValoreCampo = condizione.split(":")[1];

                // domanda da controllare è in questionario assuntivo
                if(!commonLib.isEmpty(domande)) {

                    let domanda = domande[NomeCampo];
                    if (commonLib.isEmpty(domanda)) return false;
                    return this.condizioneIsValidaInDomanda(condizione, domanda);
                }

                // domanda da controllare non è nel questionario assuntivo
                // è in analisi rischio
                var domanda_da_analisi_rischio = this.getDomandaQuestionariFromName(NomeCampo);
                if(domanda_da_analisi_rischio !== null) {
                    return this.condizioneIsValidaInDomanda(condizione, domanda_da_analisi_rischio);
                }

                // è in analisi necessità
                var domanda_da_analisi_necessita = this.getDomandaQuestionariFromName(NomeCampo);
                if(domanda_da_analisi_necessita !== null) {
                    return this.condizioneIsValidaInDomanda(condizione, domanda_da_analisi_necessita);
                }

                // la domanda a cui si fa riferimento non c'è da nessuna parte
                return false;
            },

            domandaIsVisible(domanda)
            {
                if (commonLib.isEmpty(domanda)) {
                    return true;
                }

                if (!domanda.is_visible) {
                    return false;
                }

                if (commonLib.isEmpty(domanda.is_visible_if)) {
                    return true;
                }

                // splitta condizioni separate da punto e virgola
                let condizioni = domanda.is_visible_if.split(";");
                let condizioni_valide = true;

                condizioni.forEach(cond => {

                    // se la condizione è valida, prosegui
                    if(this.condizioneIsValida(cond)) {
                        return;
                    }

                    // la condizione non è valida
                    condizioni_valide = false;
                    return false;
                });

                //console.log("domandaIsVisible", domanda.name, condizioni_valide);

                return condizioni_valide;
            },

            forzaValidazione() {
                let domande_refs = this.domandeRefs();

                for(let refKey in domande_refs) {
                    if(commonLib.isUndefined(domande_refs[refKey])) {
                        continue;
                    }
                    domande_refs[refKey].forzaValidazione();
                }
            }
        },
        async beforeMount() {
        },
        async mounted() {
           // console.log("MOUNTED");
            // Carica le domande del questionario assuntivo
            await this.loadQuestionariAssuntivi(this.formPreventivo.preventivo.id);

            //if (this.domande_caricate === true) return;
            await this.$store.dispatch("geoStore/initComuni");

            if (this.isMultirischioCasa) {
                var provincia_esteso = this.analizzaRisposta(this.getDomandaQuestionariFromName("provincia_fabbricato"));
                var provincia = await this.getProvinciaFromComune(provincia_esteso);
                var comuni = await this.getGeoComuniForProvincia(provincia);
                this.domande_multirischio_casa.comune_casa.options = comuni;
                console.log("sono qui");
            }

            if (this.isMultirischioAgricoltura) {
                provincia_esteso = this.analizzaRisposta(this.getDomandaQuestionariFromName("provincia_agricoltura"));
                provincia = await this.getProvinciaFromComune(provincia_esteso);
                comuni = await this.getGeoComuniForProvincia(provincia);
                this.domande_multirischio_agricoltura.comune_ubicazione_agricoltura.options = comuni;
            }

            if (this.isMultirischioUfficio) {
                provincia_esteso = this.analizzaRisposta(this.getDomandaQuestionariFromName("provincia_ufficio"));
                provincia = await this.getProvinciaFromComune(provincia_esteso);
                comuni = await this.getGeoComuniForProvincia(provincia);
                this.domande_multirischio_ufficio.comune_ufficio.options = comuni;
            }

            if (this.isCatastrofali) {
                provincia_esteso = this.analizzaRisposta(this.getDomandaQuestionariFromName("provincia_catastrofali"));
                provincia = await this.getProvinciaFromComune(provincia_esteso);
                comuni = await this.getGeoComuniForProvincia(provincia);
                this.domande_catastrofali.comune_catastrofali.options = comuni;
            }

            if ((this.isInfortuni) || (this.isPatrimoniale) || (this.isTCM)) {
                this.elenco_stati = await this.getStatiFormField();
                this.onPopolaStati();
            }

            this.domande_caricate = true;
        },
        async updated() {
            if (this.isMultirischioCasa) {

                var provincia_esteso = this.analizzaRisposta(this.getDomandaQuestionariFromName("provincia_fabbricato"));
                var provincia = await this.getProvinciaFromComune(provincia_esteso);
                var comuni = await this.getGeoComuniForProvincia(provincia);
                this.domande_multirischio_casa.comune_casa.options = comuni;
            }

            if (this.isCatastrofali) {

                provincia_esteso = this.analizzaRisposta(this.getDomandaQuestionariFromName("provincia_catastrofali"));
                provincia = await this.getProvinciaFromComune(provincia_esteso);
                comuni = await this.getGeoComuniForProvincia(provincia);
                this.domande_catastrofali.comune_catastrofali.options = comuni;
            }

            if ((this.isInfortuni) || (this.isPatrimoniale) || (this.isTCM)) {
                this.elenco_stati = await this.getStatiFormField();
                this.onPopolaStati();
            }
        },
    /*    async activated() {
            console.log("ACTIVATED");
            //console.log("Maremma porco sono in activated");
            // Carica le domande del questionario assuntivo
            await this.loadQuestionariAssuntivi(this.formPreventivo.preventivo.id);
            await this.$store.dispatch("geoStore/initComuni");
            //this.elenco_stati = await this.getStatiFormField();

            if (this.isMultirischioCasa) {

                var provincia_esteso = this.analizzaRisposta(this.getDomandaQuestionariFromName("provincia_fabbricato"));
                var provincia = await this.getProvinciaFromComune(provincia_esteso);
                var comuni = await this.getGeoComuniForProvincia(provincia);
                //console.log("comuni:",comuni);
                //console.log("domande_multirischio_casa:",this.domande_multirischio_casa);
                this.domande_multirischio_casa.comune_casa.options = comuni;
            }

            if ((this.isInfortuni) || (this.isPatrimoniale) || (this.isTCM)) {
                this.elenco_stati = await this.getStatiFormField();
                this.onPopolaStati();
            }

            this.domande_caricate = true;
        }, */
        async unmounted() {
        }
    }

</script>

<style>
    legend {
        font-size: 18px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
</style>
