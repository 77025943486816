<template>
    <div
        class="row justify-center"
        @input="resultDomande()"
        v-if="domande_caricate"
    >

        <div class="col-12 col-md-10" align="left">

            <legend> <strong>Proponente - Informazioni generali</strong> </legend>

            <q-list separator>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Cognome, Nome / Ragione Sociale</q-item-label>
                        <q-item-label>{{getDatiCliente("nominativo")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Indirizzo</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("indirizzo")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Civico</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("civico")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Comune</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("comune")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Provincia</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("provincia")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>CAP</q-item-label>
                        <q-item-label>{{getDatiResidenzaCliente("cap")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Codice Fiscale</q-item-label>
                        <q-item-label>{{getDatiCliente("codice_fiscale")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Partita IVA</q-item-label>
                        <q-item-label>{{getDatiCliente("partita_iva")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Comune di nascita</q-item-label>
                        <q-item-label>{{getDatiCliente("comune_nascita")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Provincia di nascita</q-item-label>
                        <q-item-label>{{getDatiCliente("provincia_nascita")}}</q-item-label>
                    </q-item-section>
                </q-item>

            </q-list>

        <!--    <q-list separator>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Cognome, Nome / Ragione Sociale</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("cognome")}} {{getValueFromNameCliente("nome")}} {{getValueFromNameCliente("ragione_sociale")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Indirizzo</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("indirizzo_residenza")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Comune</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("comune_residenza")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Provincia</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("provincia_residenza")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>CAP</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("cap_residenza")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Codice Fiscale</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("codice_fiscale")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Partita IVA</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("partita_iva")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Comune di nascita</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("comune_nascita")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Provincia di nascita</q-item-label>
                        <q-item-label>{{getValueFromNameCliente("provincia_nascita")}}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list> //-->
            <br>
            <legend> <strong>Proponente - Dati professionali</strong> </legend>
            <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Attivit&agrave; svolta</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("lavoro")}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Tipo Inquadramento</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("tipologia_attivita_persona").replaceAll('_',' ')}}</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Specializzazione</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("specializzazione")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Attivit&agrave; per Specializzazione</q-item-label>
                        <q-item-label>
                            <div v-for="(attivita,index) in AttivitaPerSpecializzazione" v-bind:key="index">
                                <q-checkbox v-model="checked" :label="attivita.replaceAll('_',' ')" color="teal" disabled />
                            </div>
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Sei un professionista iscritto ad un albo e/o abilitato all'esercizio dell'attivita` professionale?</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName("iscrizione_albo")}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <Domanda
                            v-if="domandaIsVisible(domande.data_inizio_attivita)"
                            :domanda="domande.data_inizio_attivita"
                            ref="domande.data_inizio_attivita"
                            @update:isValid="onDomandaValidated"
                        />
                    </q-item-section>

                    <q-item-section>
                        <Domanda
                            v-if="domandaIsVisible(domande.data_iscrizione_albo)"
                            :domanda="domande.data_iscrizione_albo"
                            ref="domande.data_iscrizione_albo"
                            @update:isValid="onDomandaValidated"
                        />
                    </q-item-section>

                    <q-item-section>
                        <q-item-label>
                        <!--    <q-input outlined v-model="domande.numero_iscrizione_albo" label="Numero di Iscrizione Albo" :rules="[val => !!val || 'Il numero di iscrizione all`albo e` obbligatorio']" /> //-->
                            <Domanda
                                v-if="domandaIsVisible(domande.numero_iscrizione_albo)"
                                :domanda="domande.numero_iscrizione_albo"
                                ref="domande.numero_iscrizione_albo"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-label>
                    </q-item-section>

                <!--    <q-item-section>
                        <q-item-label>
                            <Domanda
                                v-if="domandaIsVisible(domande.sezione_albo)"
                                :domanda="domande.sezione_albo"
                                ref="domande.sezione_albo"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-label>
                    </q-item-section> //-->

                </q-item>

                <q-item>
                    <q-item-section>
                        <Domanda
                            v-if="domandaIsVisible(domande.data_ultimo_aggiornamento_prof)"
                            :domanda="domande.data_ultimo_aggiornamento_prof"
                            ref="domande.data_ultimo_aggiornamento_prof"
                            @update:isValid="onDomandaValidated"
                        />
                    </q-item-section>
                    <q-item-section>
                <!--        <q-item-label>
                            <Domanda
                                v-if="domandaIsVisible(domande.numero_crediti_conseguiti)"
                                :domanda="domande.numero_crediti_conseguiti"
                                ref="domande.numero_crediti_conseguiti"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-label>//-->
                    </q-item-section>
                    <q-item-section></q-item-section>
                </q-item>
            </q-list>

            <br>
            <legend> <strong>Caratteristiche generali</strong> </legend>
            <q-list separator>
                <q-item>
                    <q-item-section>
                        <q-item-label caption v-show="!isTaylorMade">Compagnia</q-item-label>
                        <q-item-label v-show="!isTaylorMade">
                            {{getCompagniaSelezionata}}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item  v-for="(garanzia,index) in getElencoGaranzieAggiuntive" v-bind:key="index">
                    <q-item-section>
                        <q-item-label caption v-show="!isTaylorMade">{{garanzia.label}}</q-item-label>
                        <q-item-label v-show="!isTaylorMade">
                            {{analizzaRisposta(garanzia)}}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section>
                        <Domanda
                            v-if="domandaIsVisible(domande.chiude_attivita)"
                            :domanda="domande.chiude_attivita"
                            ref="domande.chiude_attivita"
                            @update:isValid="onDomandaValidated"
                        />
                    </q-item-section>
                    <q-item-section>
                        <Domanda
                            v-if="domandaIsVisible(domande.vuole_postuma)"
                            :domanda="domande.vuole_postuma"
                            ref="domande.vuole_postuma"
                            @update:isValid="onDomandaValidated"
                        />
                    </q-item-section>
                </q-item>
            </q-list>
            <br>

            <legend> <strong>Informazioni sul fatturato</strong> </legend>

            <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label>
                            <!--
                                non accettare punti o virgole
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            -->
                            <Domanda
                                v-if="domandaIsVisible(domande.fatturato.fatturato_corrente)"
                                :domanda="domande.fatturato.fatturato_corrente"
                                ref="domande.fatturato.fatturato_corrente"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label caption>Fatturato consuntivo {{(new Date().getFullYear() - 1)}}</q-item-label>
                        <q-item-label>{{getRispostaQuestionariFromName('fatturato')}} Euro</q-item-label>
                    </q-item-section>
                    <q-item-section>
                        <Domanda
                            v-if="domandaIsVisible(domande.fatturato.fatturato_due_anni_fa)"
                            :domanda="domande.fatturato.fatturato_due_anni_fa"
                            ref="domande.fatturato.fatturato_due_anni_fa"
                            @update:isValid="onDomandaValidated"
                        />
                    </q-item-section>
                    <q-item-section>
                        <Domanda
                            v-if="domandaIsVisible(domande.fatturato.fatturato_tre_anni_fa)"
                            :domanda="domande.fatturato.fatturato_tre_anni_fa"
                            ref="domande.fatturato.fatturato_tre_anni_fa"
                            @update:isValid="onDomandaValidated"
                        />
                    </q-item-section>
                </q-item>

            </q-list>
            <br>

            <legend><strong>Precedenti assicurazioni per questo rischio</strong></legend>

            <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Hai mai stipulato polizze per questo rischio?</q-item-label>
                        <q-item-label>
                            {{havePolizzaStessoRischio}}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <div v-show=" havePolizzaStessoRischio === 'SI' ">
                    <q-item >
                        <q-item-section>
                            <q-item-label caption>Impresa di assicurazione</q-item-label>
                            <q-item-label>{{getRispostaQuestionariFromName('compagnia')}}{{getRispostaQuestionariFromName('altra_compagnia')}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>Data di scadenza della copertura</q-item-label>
                            <q-item-label>{{getRispostaQuestionariFromName('scadenza_copertura')}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                <Domanda
                                    v-if="domandaIsVisible(domande.copertura_assicurativa_precedente.nome_assicuratore)"
                                    :domanda="domande.copertura_assicurativa_precedente.nome_assicuratore"
                                    ref="domande.copertura_assicurativa_precedente.nome_assicuratore"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>
                                <Domanda
                                    v-if="domandaIsVisible(domande.copertura_assicurativa_precedente.massimale)"
                                    :domanda="domande.copertura_assicurativa_precedente.massimale"
                                    ref="domande.copertura_assicurativa_precedente.massimale"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>
                                <Domanda
                                    v-if="domandaIsVisible(domande.copertura_assicurativa_precedente.franchigie_scoperti)"
                                    :domanda="domande.copertura_assicurativa_precedente.franchigie_scoperti"
                                    ref="domande.copertura_assicurativa_precedente.franchigie_scoperti"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>
                                <Domanda
                                    v-if="domandaIsVisible(domande.copertura_assicurativa_precedente.premio_lordo)"
                                    :domanda="domande.copertura_assicurativa_precedente.premio_lordo"
                                    ref="domande.copertura_assicurativa_precedente.premio_lordo"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>
                                <Domanda
                                    v-if="domandaIsVisible(domande.copertura_assicurativa_precedente.retroattivita)"
                                    :domanda="domande.copertura_assicurativa_precedente.retroattivita"
                                    ref="domande.copertura_assicurativa_precedente.retroattivita"
                                    @update:isValid="onDomandaValidated"
                                />
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                </div>
                <q-item>
                    <q-item-section >
                        <q-item-label caption>Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?</q-item-label>
                        <q-item-label>{{this.PolizzaSchifataDaCompagnia}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item>
                    <q-item-section v-if="this.PolizzaSchifataDaCompagnia === 'SI'">
                        <Domanda
                            :domanda="domande.motivo_rifiuto_compagnia"
                            ref="domande.motivo_rifiuto_compagnia"
                            @update:isValid="onDomandaValidated"
                        />
                    </q-item-section>
                </q-item>
            </q-list>
            <br>

            <legend><strong>Informazioni sui sinistri</strong></legend>

            <q-list>
                <q-item>
                    <q-item-section>
                        <q-item-label caption>Hai mai ricevuto richieste di risarcimento inerenti l`attività professionale?</q-item-label>
                        <q-item-label>
                            {{haveRichiesteRisarcimento}}
                        </q-item-label>
                    </q-item-section>
                </q-item>
                <div v-show="haveRichiesteRisarcimento === 'SI'">
                    <q-item>
                        <q-item-section>
                            <Domanda
                                :domanda="domande.sinistri.data_richiesta_risarcimento"
                                ref="domande.sinistri.data_richiesta_risarcimento"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-section>
                        <q-item-section>
                            <Domanda
                                :domanda="domande.sinistri.periodo_contestazione"
                                ref="domande.sinistri.periodo_contestazione"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <Domanda
                                :domanda="domande.sinistri.valore_richiesta_danni"
                                ref="domande.sinistri.valore_richiesta_danni"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-section>
                        <q-item-section>
                            <Domanda
                                :domanda="domande.sinistri.valore_sinistro_pagato"
                                ref="domande.sinistri.valore_sinistro_pagato"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <Domanda
                                :domanda="domande.sinistri.pagamento_con_transazione"
                                ref="domande.sinistri.pagamento_con_transazione"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-section>
                        <q-item-section>
                            <Domanda
                                :domanda="domande.sinistri.pagamento_con_sentenza_civile"
                                ref="domande.sinistri.pagamento_con_sentenza_civile"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <Domanda
                                :domanda="domande.sinistri.descrizione_fatti"
                                ref="domande.sinistri.descrizione_fatti"
                                @update:isValid="onDomandaValidated"
                            />
                        </q-item-section>
                    </q-item>
                </div>

                <q-item>
                    <q-item-section>
                        <q-item-label caption>Sei a conoscenza di circostanze che possono portare ad una richiesta di risarcimento da parte di clienti?</q-item-label>
                        <q-item-label>
                            {{haveConoscenzaPossibiliRichiesteRisarcimento}}
                        </q-item-label>
                    </q-item-section>
                </q-item>

                <q-item v-show="haveConoscenzaPossibiliRichiesteRisarcimento === 'SI'">
                    <q-item-section>
                        <Domanda
                            :domanda="domande.sinistri.descrizione_possibili_richieste_danni"
                            ref="domande.sinistri.descrizione_possibili_richieste_danni"
                        />
                    </q-item-section>
                </q-item>

            </q-list>
            <br>
        </div>
    </div>

</template>

<script>
    import { mapState } from "vuex";
    import commonLib from "@/libs/commonLib";
    import { mapFields } from 'vuex-map-fields';
    import { mapActions } from "vuex";
    // import validation from "@/libs/validation";
    import DomandaQuestionarioAssuntivo from "@/components/QuestionariAssuntivi/DomandaQuestionarioAssuntivo";

    export default {
        name: "QQQuestionarioRCMedico",
        components: {
            Domanda: DomandaQuestionarioAssuntivo
        },
        data() {
            return {
                domande_caricate: false,
                options_postuma: ["Si","No"],
                elenco_errori: [],
                totale_errori: 0,
                checked: true,
                dati_cliente: []
            }
        },

        props: {
            indice_questionario_rischio: {
                type: Number,
                required: false,
                default: 0
            },
        },

        computed: {
            ...mapFields('gestioneQuotazioni', {
                domande: "questionari_assuntivi.rcp_medico.domande"
            }),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                analisi_necessita: state => state.formPreventivo.preventivo.analisi_necessita,
                analisi_rischio: state => state.formPreventivo.preventivo.analisi_rischio,
                // dati_cliente: state => state.formPreventivo.dati_cliente,
                is_loading: state => state.formPreventivo.is_loading //,
            }),

            isTaylorMade() {
                //if (commonLib.isEmpty(this.formPreventivo.prodotti)) return false;

                //return this.formPreventivo.preventivo.prodotti[0].is_tailormade;
                return false;
            },

            PolizzaSchifataDaCompagnia() {
                let value = this.getRispostaQuestionariFromName("polizza_schifata");

                return value.toUpperCase();
            },
            AttivitaPerSpecializzazione() {
                let attivita = this.analisi_rischio[0].domande.find(a => {
                    return a.name === "attivita_per_specializzazione";
                });

               // console.log("attivita:",attivita.value);
                return attivita.value;
            },

            isPostumaVisible() {
                if (commonLib.isEmpty(this.domande)) return false;
                return this.domande.chiude_attivita === "si";
            },

            isFatturatoPrecedenteCorretto() {
                var result = true;

                var PercRif = this.percentuale_fatturato_attivita_ordinaria_anno_precedente();
                if (PercRif < 0) return false;
                if (PercRif > 100) return false;

                return result;
            },

            isFatturatoCorrenteCorretto() {
                var result = true;

                var PercRif = this.percentuale_fatturato_attivita_ordinaria_anno_corrente;
                if (PercRif < 0) return false;
                if (PercRif > 100) return false;

                return result;
            },

            lavoraUsaCanada() {
                let risposta_lavora_usa_canada =
                    this.getRispostaQuestionariFromName("area_geografica");

                return "SI" === risposta_lavora_usa_canada.toUpperCase();
            },

            getCompagniaSelezionata() {
                var result = "";
                if (commonLib.isEmpty(this.formPreventivo)) return result;

                for (var i=0;i< this.formPreventivo.preventivo.prodotti.length;i++) {
                    var prodotto = this.formPreventivo.preventivo.prodotti[i];
                    if ((prodotto.label === "RC Settore Medico") &&
                        (prodotto.is_checked)) {
                        var proposte = prodotto.proposte.filter(proposta => {
                            return proposta.is_checked;
                        });


                        if (!commonLib.isEmpty(proposte[0]))
                            result =  proposte[0].compagnia;

                            //console.log("proposte:",proposte);
                    }
                }

                return result;
            },

            getElencoGaranzieAggiuntive() {
                if (commonLib.isEmpty(this.formPreventivo)) return [];
                var prodotti = this.formPreventivo.preventivo.prodotti;
                var prodotto_attivo = prodotti.find(p => {
                    return (p.label === "RC Settore Medico") && (p.is_checked);
                });
                var proposte = prodotto_attivo.proposte;
                // Cerca quella selezionata
                var proposta = proposte.find(p => {
                    return p.is_checked;
                })


                return proposta.parametri;
            },

            haveAttivitaAltoRischio() {
                var result = false;

                // var valori = this.analisi_rischio[0].domande[7].value;
                var valori = this.getRispostaQuestionariFromName('estensioni_rc_tecnico');

                for (var i=0;i<valori.length;i++) {
                    var valore = valori[i];
                    result = (valore === "OPERE_ALTO_RISCHIO");
                }

                return result;
            },

            havePolizzaStessoRischio() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }

                //return this.analisi_rischio[0].domande[1].value.toUpperCase();
                return this.getRispostaQuestionariFromName('polizza_in_corso');
            },
            haveRichiesteRisarcimento() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }
                // return this.analisi_rischio[0].domande[9].value.toUpperCase();
                return this.getRispostaQuestionariFromName('richieste_risarcimento');
            },
            haveConoscenzaPossibiliRichiesteRisarcimento() {
                if (commonLib.isEmpty(this.analisi_rischio)) {
                    return "NO";
                }
                // return this.analisi_rischio[0].domande[11].value.toUpperCase();
                return this.getRispostaQuestionariFromName('rcp_reclami_in_corso');
            },

            attivita_aggiuntive_da_analisi_rischio() {

                let domanda_attivita_aggiuntive =
                    this.getRispostaQuestionariFromName("estensioni_rc_tecnico");

                // se non trova la domanda nell'analisi rischio
                if (commonLib.isNull(domanda_attivita_aggiuntive)) {
                    return [];
                }

                // ["AMMINISTRATORE_STABILI","ARBITRO"]
                let risposte = domanda_attivita_aggiuntive.value;

                let attivita = domanda_attivita_aggiuntive.options
                    .filter(opt => risposte.includes(opt.value));

                return attivita;
            },

            ha_funzioni_pubbliche() {
                let attivita_funzioni_pubbliche = [
                    "FUNZIONI_COMMISSIONI_TRIBUTARIE",
                    "CURATORE_LIQUIDATORE_COMMISSARIO",
                    "DELEGATO_VENDITE",
                    "FUNZIONI_PUBBLICHE_GIUDIZIALI"
                ]

                let funzioni_pubbliche_attive =
                    this.attivita_aggiuntive_da_analisi_rischio
                        .filter(a=>attivita_funzioni_pubbliche.includes(a.value));

                return funzioni_pubbliche_attive.length > 0;
            },

            fatturato_totale() {
                let fatturato = this.getRispostaQuestionariFromName("fatturato");
                return parseInt(fatturato);
            },

            prodotti_selezionati() {
                return this.formPreventivo.preventivo.prodotti.filter(p => {
                    return p.is_checked && (p.is_taylormade || p.is_taylor_made);
                });
            },

            prodotti_attivi() {
                return this.formPreventivo.preventivo.prodotti.filter(p => {
                    return p.is_checked;
                });
            },

            getIndiceQuestionarioRischio() {
                var prodotto_id = this.prodotti_selezionati[this.indice_questionario_rischio].id;
                var indice = 0;

                for (var i=0;i<this.prodotti_attivi.length;i++) {
                    if (prodotto_id === this.prodotti_attivi[i].id) indice = i;
                }

                return indice;
            }

        },
        methods: {
            ...mapActions({
                loadQuestionariAssuntivi: "gestioneQuotazioni/loadQuestionariAssuntivi",
                fetchDatiCliente: "gestioneClienti/fetchDatiCliente"
            }),
            analizzaRisposta(garanzia) {
                // type,value,options
                let result = "";
                let value = garanzia.value;
                let options = garanzia.options;
                let type = garanzia.type;

                // Cerca la label tra le options in base alla risposta inserita
                let element = options.filter(elemento => {
                    return elemento.value === value;
                });

                if (element.length === 1) {
                    value = element[0].label;
                }

                if ((value !== null) && (Array.isArray(value))) {
                    value.forEach(valore => {
                        result += '"'+valore+'", ';
                    });
                    result = result.slice(0, -2);
                    return result.replace("_"," ");
                } else {
                    if (value !== null) {
                        if (type === "checkbox") return garanzia.is_checked ? "SI" : "NO";

                        return value.replace("_"," ");
                    } else
                        return "";
                }
            },
            domandeRefs() {
                let refs = Object.entries(this.$refs)
                    .filter(entry => entry[0].indexOf("domande.")===0);

                refs = Object.fromEntries(refs);

                let refs_puliti = {};
                Object.keys(refs).map((key) => {
                    refs_puliti[key] = refs[key]
                    if(Array.isArray(refs[key])) {
                        refs_puliti[key] = refs[key][0];
                    }
                });

                return refs_puliti;
            },
            percentuale_fatturato_attivita_ordinaria_anno_precedente() {
                var percentuale = 0;

                //console.log("distribuzione_fatturato:",this.domande.distribuzione_fatturato);

                // Aggiusta la percentuale totale in base alle singole percentuali inserite
                if (!commonLib.isEmpty(this.domande)) {

                    // Itera dentro l'oggetto
                    for (var property in this.domande.distribuzione_fatturato) {
                        var element = this.domande.distribuzione_fatturato[property];

                        percentuale += parseInt(element.perc_anno_precedente.value);
                    }
                }

                return 100 - parseInt(percentuale);
            },
            percentuale_fatturato_attivita_ordinaria_anno_corrente() {
                var percentuale = 0;

                // Itera dentro l'oggetto
                for (var property in this.domande.distribuzione_fatturato) {
                    var element = this.domande.distribuzione_fatturato[property];

                    percentuale += parseInt(element.perc_anno_corrente.value);
                }

                return 100 - parseInt(percentuale);
            },
            isGruppoDomande(domanda) {
                if (commonLib.isEmpty(domanda)) return false;
                for (const key in domanda) {
                    //console.log("isGruppoDomande:",domanda,key,domanda[key],typeof(domanda[key]));
                    var isObject = typeof(domanda[key]) === "object";
                    //console.log("isObject:",isObject);
                    return isObject;
                }

                return false;
            },
            controllaDomanda(domanda) {
                if (this.isGruppoDomande(domanda)) return this.controllaGruppoDomande(domanda);
                if (typeof(domanda) !== "object") return true;

                // controlla se domanda è visibile
                // se non visibile, è valida perchè non serve validazione
                if (domanda.is_visible_if !== "") {
                    // console.log("Rifiuto_compagnia",this.domande.rifiuto_compagnia.value);
                    if (!this.condizioneIsValida(domanda.is_visible_if)) {
                        // console.log("Campo non visibile");
                        return true; // Vuol dire che non e` visibile quindi esce senza fare altri controlli
                    }
                }

                // domanda visibile, fai validazione
                return true;
                //domanda.is_valid;

                // if (domanda.validation === "") return true; // Se non ci sono controlli di validazione esce senza errori

                // // splitta tutti i comandi di verifica
                // let attributi = domanda.validation.split("|");
                // let {Messaggio,esito} = validation.verificaRegole(attributi,domanda.value);

                // if (domanda.label === "Breve descrizione dei motivi del rifiuto") {
                //     console.log("Esito verifica:",domanda.label, domanda.value, attributi, Messaggio, esito, domanda.is_visible_if);
                // }

                // if (esito)
                //     console.log("Situazione verifica ",domanda.name,attributi,domanda.value,esito,Messaggio);

                // //console.log("Messaggio:",Messaggio);

                // return !esito;
            },
            controllaGruppoDomande(domande) {
                let result = true;
                for (const key in domande) {
                    if (typeof(domande[key]) === "object")
                        result = result && this.controllaDomanda(domande[key]);
                }

                return result;
            },
            async isQuestionarioValid() {
                var questionario_is_valid = this.elenco_errori.length === 0;

                // for (const key in this.domande) {
                //     var domanda = this.domande[key];

                //     //console.log("isQuestionarioValid:domanda:",domanda.label,typeof(domanda));
                //     if (typeof(domanda) === "object")
                //         result = result && this.controllaDomanda(domanda);
                // }

                //console.log("result:",result);

                console.debug("isQuestionarioValid",questionario_is_valid);
                this.$emit("update:isValid", questionario_is_valid);

                return questionario_is_valid;
            },
            async onDomandaValidated({isValid, domanda})
            {
                this.elenco_errori =
                    this.elenco_errori.filter(d => d !== domanda.name);

                // domanda visibile e con errore
                if(this.domandaIsVisible(domanda) && !isValid) {
                    console.debug("onDomandaValidated", domanda.name, isValid);
                    this.elenco_errori.push(domanda.name);
                }

                await this.isQuestionarioValid();
            },
            resultDomande() {
                return null;
            },
        /*    getValueFromNameCliente(name) {
                if (commonLib.isEmpty(this.formPreventivo)) return "";

                var dati_cliente = this.formPreventivo.dati_cliente[0].domande;
                var result = dati_cliente.find(dato => {
                    return dato.name === name;
                });

                if (commonLib.isEmpty(result.value)) return "";

                return result.value.toUpperCase();
            }, */
            getDatiCliente(index) {
                if (commonLib.isEmpty(this.dati_cliente)) return "";

                return this.dati_cliente[index];
            },
            getDatiResidenzaCliente(campo) {
                if(commonLib.isEmpty(this.dati_cliente)) return "";

                let value = this.dati_cliente.altri_dati[0].indirizzi.filter(v => {
                    return v.tipo === "residenza";
                });

                return value[0][campo];
            },

            getRispostaAnalisiNecessitafromName(name) {
                if (!commonLib.isEmpty(this.analisi_necessita)) {
                    var domanda = this.analisi_necessita.find(d => {
                        return d.name === name;
                    });

                    // Se la domanda non e` presente nel questionario allora domanda=undefined
                    if (commonLib.isEmpty(domanda)) return "";

                    return domanda.value.toUpperCase();
                }

                return "";
            },

            getDomandaAnalisiRischioFromName(name) {
                // analisi rischio RC sempre in posizione 0
                // TODO: andare per id

                // non c'è analisi rischio
                if (commonLib.isEmpty(this.analisi_rischio))
                    return null;

                // non ci sono domande analisi rischio
                //let analisi_rischio_rc = this.analisi_rischio[0];
                let indice_questionario = this.getIndiceQuestionarioRischio;
                let analisi_rischio_rc = this.analisi_rischio[indice_questionario];


                if (commonLib.isEmpty(analisi_rischio_rc)) {
                    return null;
                }

                // cerca la domanda
                var domanda = analisi_rischio_rc.domande.find(d => {
                    return d.name === name;
                });

                // domanda non trovata
                if(commonLib.isUndefined(domanda)) {
                    return null;
                }

                return domanda;
            },

            getDomandaAnalisiNecessitaFromName(name) {
                // non c'è analisi necessita
                if (commonLib.isEmpty(this.analisi_necessita))
                    return null;

                // cerca la domanda
                var domanda = this.analisi_necessita.find(d => {
                    return d.name === name;
                });

                // domanda non trovata
                if(commonLib.isUndefined(domanda)) {
                    return null;
                }

                return domanda;
            },

            getRispostaAnalisiRischioFromName(name) {
                // analisi rischio RC sempre in posizione 0
                // TODO: andare per id
                if (commonLib.isEmpty(this.analisi_rischio)) return "";

                let indice_questionario = this.getIndiceQuestionarioRischio;
                let analisi_rischio_rc = this.analisi_rischio[indice_questionario];

                if (!commonLib.isEmpty(analisi_rischio_rc)) {
                    var domanda = analisi_rischio_rc.domande.find(d => {
                        return d.name === name;
                    });

                    if (commonLib.isEmpty(domanda)) return "";

                    var risposta =  domanda.value;

                    if (!Array.isArray(risposta))
                        return risposta.toUpperCase();

                    return risposta;
                }

                return "";

            },

            getRispostaQuestionariFromName(name) {
                var risposta = this.getRispostaAnalisiRischioFromName(name);
                if (commonLib.isEmpty(risposta)) risposta = this.getRispostaAnalisiNecessitafromName(name);

                console.log("risposta:",risposta);

                return risposta;
            },

            getDomandaQuestionariFromName(name) {
                var domanda = this.getDomandaAnalisiNecessitaFromName(name);
                if (commonLib.isEmpty(domanda)) domanda = this.getDomandaAnalisiRischioFromName(name);

                return domanda;
            },

            getImportoFatturatoEstero(perc) {
                return parseInt(this.fatturato_totale * perc / 100);
            },

            getCurrentYear(decresci) {
                var data = new Date();
                return "Consuntivo "+(data.getFullYear() - decresci);
            },

            async inizializzaStrutturaFatturato() {
                return [];
            },

            getImportoPrecedente(perc) {
                var fatturato = parseInt(this.getRispostaQuestionariFromName("fatturato"));
                // var fatturato = parseInt(this.analisi_rischio[0].domande[0].value); // Importo del fatturato
                var importo = fatturato * parseInt(perc) / 100;

                return parseInt(importo);
            },

         /*   getImportoCorrente(perc) {
                if (commonLib.isEmpty(this.domande)) return 0;

                var fatturato = parseInt(this.domande.fatturato.fatturato_corrente.value);
                var importo = fatturato * parseInt(perc) / 100;

                return parseInt(importo);
            },*/

            condizioneIsValidaInDomanda(condizione, domanda)
            {
                // Recupera i nome del campo di riferimento
                // let NomeCampo = condizione.split(":")[0];
                let ValoreCampo = condizione.split(":")[1];

                if (domanda === null) return true;

                // la domanda a cui la condizione fa riferimento non è visibile
                if(!this.domandaIsVisible(domanda)) {
                    //if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                    //    console.log("condizioneIsValida.perc_anno_corrente !domandaIsVisible");
                    //}
                    return false;
                }

                // il value non è valorizzato
                if(commonLib.isNullOrEmpty(domanda.value)) {
                    //if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                    //    console.log("condizioneIsValida.perc_anno_corrente isNullOrEmpty(domanda.value)");
                    //}
                    return false;
                }

                // la risposta è una stringa, ci basta controllare che la
                // condizione contenga la stringa
                // es. risposta "ciccio", condizione "ciccio,pasticcio,mario"
                if(typeof domanda.value === "string") {
                    return ValoreCampo.includes(domanda.value);
                }

                // se la risposta alla domanda è un array, controlliamo che
                // la condizione sia contenuta nell'array di risposta

                // ValoreCampo = val1,val2,val3,val4
                // domanda.value = ["val5", "val1"]
                if(ValoreCampo.indexOf(",") > 1) {
                    var elenco = ValoreCampo.Split(",");
                    var result = true;
                    elenco.forEach(item => {
                        result &= domanda.value.includes(item);
                    });

                    return result;
                }

                return domanda.value.includes(ValoreCampo);
            },

            // interpreta le singoli condizioni di is_visible_if
            // esempio is_visible_if:"lavoro:medico"
            // condizione "lavoro:medico"
            condizioneIsValida(condizione)
            {
                // Recupera i nome del campo di riferimento
                let NomeCampo = condizione.split(":")[0];
                // let ValoreCampo = condizione.split(":")[1];


                // domanda da controllare è in questionario assuntivo
                if(!commonLib.isEmpty(this.domande[NomeCampo])) {

                    //if(condizione == "estensioni_rc_tecnico:AMMINISTRATORE_STABILI") {
                    //    console.log("condizioneIsValida.perc_anno_corrente","questionario assuntivo" );
                    //}

                    let domanda = this.domande[NomeCampo];
                    return this.condizioneIsValidaInDomanda(condizione, domanda);
                }

                // domanda da controllare non è nel questionario assuntivo

                // è in analisi rischio
                var domanda_da_analisi_rischio = this.getDomandaAnalisiRischioFromName(NomeCampo);
                if(domanda_da_analisi_rischio !== null) {
                    return this.condizioneIsValidaInDomanda(condizione, domanda_da_analisi_rischio);
                }

                // è in analisi necessità
                var domanda_da_analisi_necessita = this.getDomandaAnalisiNecessitaFromName(NomeCampo);
                if(domanda_da_analisi_necessita !== null) {
                    return this.condizioneIsValidaInDomanda(condizione, domanda_da_analisi_necessita);
                }

                // la domanda a cui si fa riferimento non c'è da nessuna parte
                return false;
            },

            domandaIsVisible(domanda)
            {
                if (commonLib.isEmpty(domanda)) {
                    return true;
                }

                if (!domanda.is_visible) {
                    return false;
                }

                if (commonLib.isEmpty(domanda.is_visible_if)) {
                    return true;
                }

                // splitta condizioni separate da punto e virgola
                let condizioni = domanda.is_visible_if.split(";");
                let condizioni_valide = true;

                condizioni.forEach(cond => {

                    // se la condizione è valida, prosegui
                    if(this.condizioneIsValida(cond)) {
                        return;
                    }

                    // la condizione non è valida
                    condizioni_valide = false;
                    return false;
                });

                // console.log("domandaIsVisible", domanda.name, condizioni_valide);

                return condizioni_valide;
            },

            forzaValidazione() {
                let domande_refs = this.domandeRefs();

                for(let refKey in domande_refs) {
                    if(commonLib.isUndefined(domande_refs[refKey])) {
                        continue;
                    }
                    domande_refs[refKey].forzaValidazione();
                }
            }
        },
        async beforeMount() {
        },
        async mounted() {
            await this.loadQuestionariAssuntivi(this.formPreventivo.preventivo.id);
            this.domande_caricate = true;

            let guid_cliente = this.formPreventivo.IDCliente;
            this.dati_cliente = await this.fetchDatiCliente(guid_cliente);

            console.log("dati cliente:",this.dati_cliente);

            this.domande_caricate = true;
        },

        async unmounted() {
        }
    }

</script>

<style>
    legend {
        font-size: 18px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
</style>
