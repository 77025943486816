<template>
    <div v-if="renderizza">
        <!--
            TODO:
            - is_visible_if delle options (getRisposte)
            - error, validazione
                + onBlur
                + date
                + min, max non funzionano
                + q-option-group checkbox/radio e select devono essere selezionate
            - append, prepend, aggiungi before-after
            - select stronza senza label
        -->

            <!--
                :error="isNotValid"
                :error-message="errorMessage"
                @blur="onBlur()"
            -->

<!------------------------------------------------------------------------------
----    INPUT NUMBER
------------------------------------------------------------------------------->
        <fieldset
            v-if="getDomanda.type==='number'"
            class="q-pa-none qma-none no-border"
        >
            <legend>{{getDomanda.label}}</legend>
            <q-input
                :ref="getDomanda.type"
                type="number"
                outlined
                dense
                :name="getDomanda.name"
                v-model="getDomanda.value"
                :disable="getDomanda.is_disabled"
                :filled="getDomanda.is_disabled"
                @input="$emit('update:domanda', domanda)"

                :rules="[
                    val => !isNotValid(val) || errorMessage
                ]"
            >
                <template v-slot:prepend>
                    {{ getDomanda.label_prepend }}
                </template>
                <template v-slot:append>
                    {{ getDomanda.label_append }}
                </template>
            </q-input>
        </fieldset>
<!------------------------------------------------------------------------------
----    INPUT PERCENTUALE
------------------------------------------------------------------------------->
        <fieldset
            v-if="getDomanda.type==='percentuale'"
            class="q-pa-none qma-none no-border"
        >
            <legend>{{getDomanda.label}}</legend>
                <!-- :rules="[
                    val => parseInt(val) >= 0 || 'Il campo deve essere maggiore o uguale a zero',
                    val => parseInt(val) <= 100 || 'Il campo deve essere minore o uguale a 100'
                ]" //-->
            <q-input
                :ref="getDomanda.type"
                type="number"
                outlined
                dense
                :name="getDomanda.name"
                v-model="getDomanda.value"
                :disable="getDomanda.is_disabled"
                :filled="getDomanda.is_disabled"
                @input="$emit('update:domanda', getDomanda)"

                :rules="[
                    val => !isNotValid(val) || errorMessage
                ]"
            >
                <template v-slot:append>
                    %
                </template>

            </q-input>

            {{getPremioRiferimento}} Euro
        </fieldset>

<!------------------------------------------------------------------------------
----    SELECT
------------------------------------------------------------------------------->
        <fieldset
            v-if="getDomanda.type==='select'"
            class="q-pa-none qma-none no-border"
        >
            <legend>{{getDomanda.label}}</legend>

            <q-select v-if="getDomanda.type==='select'"
                :ref="getDomanda.type"
                dense
                virtual-scroll-slice-size="0"
                virtual-scroll-item-size="0"
                :name="getDomanda.name"
                outlined emit-value map-options
                v-model="getDomanda.value"
                :disable="getDomanda.is_disabled"
                :filled="getDomanda.is_disabled"
                :options="getDomanda.options"
                @input="$emit('update:domanda', getDomanda)"
                :rules="[
                    val => !isNotValid(val) || errorMessage
                ]"
            >
            </q-select>
        </fieldset>

        <fieldset v-if="getDomanda.type=='input_select'" class="q-pa-none qma-none no-border">
            <legend>{{getDomanda.label}}</legend>

            <q-select
                ref="input_select"
                emit-value
                dense
                map-options
                :name="getDomanda.name"
                virtual-scroll-slice-size="0"
                virtual-scroll-item-size="0"
                outlined
                use-input
                input-debounce="0"
                :disable="getDomanda.is_disabled"
                :filled="getDomanda.is_disabled"
                v-model="getDomanda.value"
                :options="getDomanda.options"
                @blur="onBlur()"
                @input="$emit('qq-domanda-change',getDomanda)"
                @filter="onInputSelectFilter"
                :rules="[
                    val => !isNotValid(val) || errorMessage
                ]"
            >
            <template v-slot:no-option>
                <q-item>
                    <q-item-section class="text-grey">
                    Nessun risultato
                    </q-item-section>
                </q-item>
            </template>

        </q-select>
    </fieldset>

<!------------------------------------------------------------------------------
----    INPUT DATE
------------------------------------------------------------------------------->
        <fieldset v-if="getDomanda.type==='date'" class="q-pa-none qma-none no-border" >
            <legend>{{getDomanda.label}}</legend>
            <!--</q-input>:rules="[val => dataValida(val) || 'Data non valida']" //-->
            <q-input
                :ref="getDomanda.type"
                outlined
                mask="##/##/####"
                dense
                :name="getDomanda.name"
                :rules="[
                    val => !isNotValid(val) || errorMessage
                ]"

                v-model="getDomanda.value"

                :disable="getDomanda.is_disabled"
                :filled="getDomanda.is_disabled"

                @input="$emit('update:domanda', getDomanda)"
            >
                <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy transition-show="scale" transition-hide="scale">
                            <q-date
                                v-model="getDomanda.value"
                                mask="DD/MM/YYYY"
                                v-show="visible"
                            >
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Chiudi" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
            </q-input>
        </fieldset>
<!------------------------------------------------------------------------------
----    INPUT TEXT
------------------------------------------------------------------------------->
        <fieldset
            v-if="getDomanda.type==='text'"
            class="q-pa-none qma-none no-border"
        >
            <legend>{{getDomanda.label}}</legend>
            <q-input  v-if="getDomanda.type == 'text'"
                :ref="getDomanda.type"
                outlined
                dense
                :name="getDomanda.name"
                class="textbox"
                v-model="getDomanda.value"
                :disable="getDomanda.is_disabled"
                :filled="getDomanda.is_disabled"
                @input="$emit('update:domanda', getDomanda)"
                :placeholder="getDomanda.placeholder"

                :rules="[
                    val => !isNotValid(val) || errorMessage
                ]"
            />
        </fieldset>
<!------------------------------------------------------------------------------
----    INPUT TEXTAREA
------------------------------------------------------------------------------->
        <fieldset
            v-if="getDomanda.type==='textarea'"
            class="q-pa-none qma-none no-border"
        >
            <legend>{{getDomanda.label}}</legend>
            <q-input
                :ref="getDomanda.type"
                outlined
                dense
                :name="getDomanda.name"
                type="textarea"
                v-model="getDomanda.value"
                :disable="getDomanda.is_disabled"
                :filled="getDomanda.is_disabled"
                @input="$emit('update:domanda', getDomanda)"
                :placeholder="getDomanda.placeholder"

                :rules="[
                    val => !isNotValid(val) || errorMessage
                ]"
            />
        </fieldset>
<!------------------------------------------------------------------------------
----    INPUT GROUP_CHECKBOX
------------------------------------------------------------------------------->
        <div v-if="getDomanda.type === 'group_checkbox'" align="left">
            <fieldset v-show="visible" class="q-mb-md">
                <legend><strong>{{getDomanda.label}}</strong></legend>
                    <q-option-group
                        type="checkbox"
                        :name="getDomanda.name"
                        v-model="getDomanda.value"
                        :options="getDomanda.options"
                        :disable="getDomanda.is_disabled || disabled"
                        @input="$emit('update:domanda', getDomanda)"
                        :rules="[
                            val => !isNotValid(val) || errorMessage
                        ]"
                    />
            </fieldset>
        </div>
<!------------------------------------------------------------------------------
----    INPUT CHECKBOX
------------------------------------------------------------------------------->
        <div v-if="getDomanda.type === 'checkbox'" align="center">
            <q-checkbox
                :name="getDomanda.name"
                v-model="getDomanda.value"
                :label="getDomanda.label"
                :disable="getDomanda.is_disabled"
                @input="$emit('update:domanda', getDomanda)"
            />
        </div>


<!------------------------------------------------------------------------------
----    INPUT RADIO CON OPTION GROUP
------------------------------------------------------------------------------->
        <fieldset
            v-if="getDomanda.type==='radio'"
            class="q-pa-none qma-none no-border"
        >
            <legend>{{getDomanda.label}}</legend>
            <q-option-group
                type="radio"
                :name="getDomanda.name"
                v-model="getDomanda.value"
                :options="getDomanda.options"
                :disable="getDomanda.is_disabled"
                @input="$emit('update:domanda', getDomanda)"
                @click.native="isNotValid(getDomanda.value)"
            />
        </fieldset>

        <fieldset v-show="visible" class="q-mb-md" v-if="getDomanda.type==='big-radio'">

            <div class="row">
                <div class="cl-12 col-md-8" v-html="domanda.label"></div>
                <div class="cl-12 col-md-4" align="center">
                    <q-option-group
                        :options="getDomanda.options"
                        :label="getDomanda.label"
                        :name="getDomanda.name"
                        type="radio"
                        v-model="getDomanda.value"
                        :disable="getDomanda.is_disabled"
                        @input="$emit('update:domanda', getDomanda)"
                        @click.native="isNotValid(getDomanda.value)"
                    />
                </div>
            </div>
        </fieldset>


    </div>
</template>

<script>
    import commonLib from "@/libs/commonLib";
    import validation from "@/libs/validation";

    export default {
        name: "DomandaQuestionarioAssuntivo",
        data() {
            return {
                renderizza: true,
                has_error: false,
                errorMessage: "",
                selectedField: false,
                valore: 0
            }
        },
        props: {
            noborder: {
                type: Boolean,
                default: false
            },
            riferimento: {
                type: Number,
                required: false,
                default: 0
            },
            domanda: {
                type: Object,
                required: true
            },
            visible: {
                type: Boolean,
                required: false,
                default: true
            },
            min: {
                type: Number,
                required: false,
                default: 0
            },
            max: {
                type: Number,
                required: false,
                default: 9999999
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            getDomanda() {
                if (commonLib.isEmpty(this.domanda)) return {};

                return this.domanda;
            },
            fieldRef() {
                if (commonLib.isEmpty(this.domanda)) return null;
                if(commonLib.isUndefined(this.$refs[this.domanda.type])) {
                    return null;
                }

                return this.$refs[this.domanda.type];
            },
            nativeElement() {
                if(this.fieldRef === null) {
                    return null;
                }

                return this.fieldRef.getNativeElement();
            },
            getPremioRiferimento() {
                if (isNaN(this.riferimento)) return 0;

                return this.riferimento;
            },
            getDomandaType() {
                if (commonLib.isEmpty(this.domanda)) return "";
                return this.domanda.type+"";
            }
        },
        methods: {
            forzaValidazione() {
                if(this.fieldRef === null) {
                    return;
                }
                if (commonLib.isEmpty(this.domanda)) return;

                this.isValid();
                this.fieldRef.resetValidation();
                this.fieldRef.validate(this.domanda.value);
            },

            isValid(value = null) {
                if (commonLib.isEmpty(this.domanda)) return false;
                if(value == null) {
                    value = this.domanda.value;
                }
                return !this.isNotValid(value);
            },

            isNotValid(value) {
                if (commonLib.isEmpty(this.domanda)) return false;

                this.resetErrorMessage();

                if (commonLib.isEmpty(this.domanda.validation)) return false;

                // splitta tutti i comandi di verifica
                let attributi = this.domanda.validation.split("|");
                let {Messaggio, esito : has_error} = validation.verificaRegole(attributi,value);

                //this.$emit('error-checked',this.domanda.name,esito);
                if(has_error) {
                    this.setErrorMessage(Messaggio);
                }


                this.$emit('error-checked', has_error);
                this.$emit('update:isValid', {isValid: !has_error, domanda: this.domanda});

                return has_error;
            },
            // valida le date nel formato gg/mm/aaaa
            dataValida: function(dateValue)
            {
                if (commonLib.isEmpty(dateValue)) return false;
                // la data deve essere di 10 caratteri
                if (dateValue.length != 10) return false;

                // splittando per / deve avere 3 porzioni
                let elements = dateValue.split("/");
                if (elements.length != 3) return false;

                // riformatta in formato ISO aaaa-mm-gg
                let data = elements[2]+"-"+elements[1]+"-"+elements[0];

                // se la data non è valida, Date.parse() torna NaN
                let data_parsata = Date.parse(data);

                return !isNaN(data_parsata);
            },

            async onInputSelectFilter(input_value, update, abort) {
                var domanda  = this.domanda;
                await this.$emit('qq-domanda-inputSelect-filter', {
                    input_value,
                    domanda,
                    update,
                    abort
                });
            },
            onFilterDataCharge(val) {
                this.$emit("qq-domanda-filter",val);
            },
            onBlur() {
                this.attivaValidazione();
                // this.$emit("domanda-blur");
            },
            resetErrorMessage() {
                this.has_error = false;
                this.errorMessage = "";
            },
            setErrorMessage(Messaggio) {
                this.has_error = true;
                this.errorMessage = Messaggio;
            },
            attivaValidazione() {
                this.selectedField = true;
            },
            btnClickEvent(label) {
                this.$emit('on-btn-clicked',label);
            }
        },
        beforeDestroy() {
            if (!commonLib.isEmpty(this.domanda))
            this.isNotValid(this.domanda.value);
        },
        updated() {
            // forza validazione domanda
            if (!commonLib.isEmpty(this.domanda))
                this.isNotValid(this.domanda.value);
        },
        mounted() {
            // Se la domanda fa riferimento ad un checkbox, converte il value
            // da stringa a bool
            if (commonLib.isEmpty(this.domanda))
            {
                this.renderizza = false;
                return;
            }

            if (this.domanda.type === "checkbox") {
                if (this.domanda.value === "true") this.domanda.value = true;
                if (this.domanda.value === "false") this.domanda.value = false;
            }

            // forza validazione domanda
            this.isNotValid(this.domanda.value);
        }
    }
</script>

<style scoped>
    legend {
        font-size: 14px;
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .q-radio--dark .q-radio__inner--truthy {
        color: #fff;
    }
</style>
